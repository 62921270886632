import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/contextAuth';
import { useUser } from '../../context/contextUser';
import { useDesign } from '../../context/contextDesign';
import { Link } from 'react-router-dom';
import LogoImg from '../asset/img/logo/Save-Logo-White-2.png'
import menuItems from '../../config/menuItems';

const SideNavbar=()=>{
  const { user, upgradePlan } = useAuth();
  const { sidebarWidthVal, extendedVal } = useDesign();
  const { isSidebarExtended } = useUser();
  //console.log(upgradePlan);
  return(
    <nav className="navbar navbar-dark">
      <div className="sidebar " style={{
          width: sidebarWidthVal,
          height: '100vh',
          transition: 'all 0.2s',
        }}>
        <div className="sidebarExtended d-flex flex-column 
          p-0 h-100 w-auto bg-transparent position-absolute"
          style={{transition: 'all 0.2s',}}>
          <SidebarLogo />
          <SidebarItems />
          <div className="sidebarPseudoFooter position-absolute z-2"
            style={{
              backgroundImage: 'linear-gradient(90deg, rgb(0, 18, 66), rgb(0 5 60))',
              marginBottom: isSidebarExtended? !upgradePlan? 220:80:60,
              left: 0, bottom: isSidebarExtended? 0:-400,
              transition: isSidebarExtended ?'all 0.7s':'all 0.0s',
            }}/>
        </div>
        <div className="sidebarExtended p-2 position-absolute z-1"
          style={{                              
            left: 0, bottom: isSidebarExtended? 0:-400, width: extendedVal,
            backgroundImage: 'linear-gradient(90deg, rgb(0, 18, 66), rgb(0 5 60))',
            transition: isSidebarExtended ?'all 0.7s':'all 0.0s',
          }}>
          {(!upgradePlan) && <SubscriptionCard />}
          <TermsConditions />
        </div>
      </div>
    </nav>
  )
}

export default SideNavbar;


const SidebarLogo =()=>{  
  const { isSidebarExtended } = useUser();
  const logoStyleCropped = {width: '72px', transition: 'all 0.2s', marginRight: -9,};
  const logoStyle = {width: '126px', transition: 'all 0.2s',};
  return(
    <div className={`logo d-flex justify-content-center align-items-center mx-auto mb-2
      ${isSidebarExtended?'mt-4':'mt-2'}`}
      style={{
        height: isSidebarExtended ? '200px':'60px',
        transition: 'all 0.3s',
      }}>
      <a href='/'>
        <img className='h-100' src={LogoImg} alt='{SAVE}'
          style={isSidebarExtended ? logoStyle : logoStyleCropped} />
      </a>
    </div>
  )
}

const SidebarItems =()=>{
  const { upgradePlan } = useAuth();
  const { activeNav, setActiveNav } = useDesign();
  const { isSidebarExtended, setUrlVulTableToggle, extendedVal } = useUser();
  const handleItemClick = (itemId) => {
    setActiveNav(itemId);
  };
  const [isVisible, setIsVisible] = useState(true);
  
  return(
    <div
      className="sidebarExtendedlist nav scrollbar-primary d-inline p-2"
      style={{        
        height: isSidebarExtended? !upgradePlan?'calc(100% - 460px)':'calc(100% - 300px)': '80%',
        width: isSidebarExtended? extendedVal:88,
        transition: 'all 0.2s' }}>
      {menuItems.map((item) => (
        <div key={item.id} className="nav-item" onClick={() => handleItemClick(item.id)}>
          <Link
            to={item.path}
            onClick={() => {setUrlVulTableToggle(false)}}
            className={`nav-link text-white my-2 d-flex rounded-3 ${
              activeNav === item.id ? 'active' : ''
            }`}
            style={{         
              backgroundColor: activeNav === item.id ? '#344266' : '',
              height: isSidebarExtended ? '50px' : '60px',
              transition: 'all 0.2s',
            }}>
            <div className="d-flex align-items-center">
              <i className={`${
                  activeNav === item.id ? item.activeIcon : item.icon
                } my-2`}
                style={{
                  margin: 'auto',
                  marginRight: isSidebarExtended ? '12px' : '6px',
                  marginLeft: isSidebarExtended ? '10px' : '6px',
                  fontSize: isSidebarExtended ? '16px' : '20px',                  
                  transition: 'all 0.2s',
                }}
              />
              <div
                // className={`sidebarTextitems ${isVisible ? 'fade-in' : 'fade-out'}`}
                style={{
                  marginLeft: isSidebarExtended ? '0px' : '-210px',
                  display: isVisible ? 'block' : 'none',
                  fontWeight: activeNav === item.id ? '700': 'normal',
                  transition: 'margin-left 0.2s',
                  zIndex: isSidebarExtended ? 0 : -2,
                }}>
                {item.title}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}


const SubscriptionCard = () => {
  const { toggleUpgradeCard } = useDesign();
  return (
    <div className="card d-flex rounded-4 shadow col-12"
      style={{
        background: '#17234D', color: '#dedede',
        transition: 'all 0.3s', 
      }}>
      <div className="card-body text-center">
        <h4 className="card-title">Pro Access</h4>
        <p className="card-text" style={{fontSize: 13,}}>
          Upgrade the plan to get access to unlimited reports
        </p>      
        <button className="btn btn-primary border-0 mt-2"
          style={{backgroundColor: 'rgb(52 66 102)', width: 150,}} 
          onClick={toggleUpgradeCard}>
          Upgrade
        </button>
      </div>
    </div>
  );
}

const TermsConditions = () => {
  return(
    <div className='py-2 pb-4 d-flex justify-content-center'>
      <a href='/Terms-Conditions' className='text-white'
        style={{fontSize: '12px', fontWeight: 500}}>
        Terms & Conditions
      </a>
    </div>
  )
}
