import React, { useContext, useState } from 'react';
import { useUser } from '../context/contextUser';
import { useNavigate, Link } from 'react-router-dom';
import { signup } from '../api/users';
import Loader3 from './components/Loaders/Loader-3/Loader-3';
import LogoImg from './asset/img/logo/Save-Logo-White-2.png';

export default function SignUpPage(){
  const { setTempUserData } = useUser();
  const [loading, setLoading] = useState(false);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const widthVal = 540;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    // Split the full name into first name and last name
    const [firstName, ...lastNameParts] = fullName.trim().split(' ');
    const lastName = lastNameParts.join(' ');
  
    // Create an object to handle the user data
    const data = {
      username,
      password,
      first_name: firstName,
      last_name: lastName,
      company,
      designation,
      email,
      phone_number: phoneNumber,
    };
  
    try {
      const response = await signup(data);
      if (response) {
        setTempUserData(response);
        localStorage.setItem('authToken', response.token);
        navigate('/mfa');
      } else {
        setError('Signup failed');
        setTempUserData(null);
        navigate('/');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setError(error.message);
    }finally {
      setLoading(false);
    }
  };  

  return (
    <>      
      <div className='signUpWrapper' 
        style={{width: '100vw', height: '100vh', background: 'var(--bg-grad-1)',}}>            
        {loading ? (<Loader3 />):(
          <div
            className='position-absolute top-50 start-50 translate-middle'
            style={{width: widthVal, color: 'white', marginTop: '-6vh',}}>
            <div className='logo' style={{padding: 40}}>
              <img className='rounded mx-auto d-block' src={LogoImg} alt='Save Logo' style={{height: 50}}/>
            </div>
            <form onSubmit={handleSubmit} action='POST' className="row g-3 text-white">
              {/* Full Name */}
              <div className="col-md-12">
                <input 
                  type="text" 
                  placeholder="Full Name" 
                  className="form-control" 
                  value={fullName} 
                  onChange={(e) => setFullName(e.target.value)}
                  aria-label="Full Name" 
                  required 
                />
              </div>

              {/* Company and Designation */}
              <div className="col-md-6">
                <input 
                  type="text" 
                  placeholder="Company" 
                  className="form-control" 
                  value={company} 
                  onChange={(e) => setCompany(e.target.value)}
                  aria-label="Company" 
                  required 
                />
              </div>
              <div className="col-md-6">
                <input 
                  type="text" 
                  placeholder="Designation" 
                  className="form-control" 
                  value={designation} 
                  onChange={(e) => setDesignation(e.target.value)}
                  aria-label="Designation" 
                  required 
                />
              </div>

              {/* Email and Phone */}
              <div className="col-md-6">
                <input 
                  type="email" 
                  placeholder="Email" 
                  className="form-control" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                  aria-label="Email" 
                  required 
                />
              </div>
              <div className="col-md-6">
                <input 
                  type="text" 
                  placeholder="Phone Number" 
                  className="form-control" 
                  value={phoneNumber} 
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  aria-label="Phone Number" 
                  required 
                />
              </div>

              {/* Username and Password */}
              <div className="col-md-6">
                <input 
                  type="text" 
                  placeholder="Username" 
                  className="form-control" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)}
                  aria-label="Username" 
                  required 
                />
              </div>
              <div className="col-md-6">
                <input 
                  type="password" 
                  placeholder="Password" 
                  className="form-control" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)}
                  aria-label="Password" 
                  required 
                />
              </div>

              {/* Confirm Password */}
              <div className="col-md-12">
                <input 
                  type="password" 
                  placeholder="Confirm Password" 
                  className="form-control" 
                  value={confirmPassword} 
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  aria-label="Confirm Password" 
                  required 
                />
              </div>

              {/* Remember Me Checkbox */}
              <div className="col-12 text-center pt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                  checked={isRememberMeChecked}
                  onChange={(e) => setIsRememberMeChecked(e.target.checked)}
                  style={{ marginRight: 10 }}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember Me
                </label>
              </div>

              {/* Submit Button */}
              <center>
                <div className="error" style={{ fontSize: '14px', color: '#ffd700', }}>{error}</div>  
                <button 
                  className="btn" 
                  type="submit"                               
                  style={{
                    border: 'none', marginTop: 20,
                    width: 270, height: 40,               
                    color: 'white',
                    background: '#d80000',
                  }}>Submit
                </button>
                <div style={{ fontSize: '14px', marginTop: 10 }}>
                  If you already have an account 
                  <Link to="/SignIn" style={{ color: 'red' }}>
                    <b> Sign In</b>
                  </Link>
                </div>
              </center>
            </form>
          </div>
        )}                  
      </div>
    </>
  );
}