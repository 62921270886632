import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/contextAuth';
import { UserProvider } from './context/contextUser';
import { DesignProvider } from './context/contextDesign';
import { ChatProvider } from './context/contextChat';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <DesignProvider>
          <ChatProvider>
            <App />
          </ChatProvider>
        </DesignProvider>
      </UserProvider> 
    </AuthProvider> 
  </React.StrictMode>
);

reportWebVitals();