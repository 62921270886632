import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useUser } from '../context/contextUser';
import { useNavigate, Link } from 'react-router-dom';

export default function MFA() {
  const { getVerify, setLoadingAuth } = useAuth();
  const { LoadUserData } = useUser();
  const [seconds, setSeconds] = useState(60);
  const [timerActive, setTimerActive] = useState(true);
  const [code, setCode] = useState(Array(6).fill('')); // Array for 6-digit code
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!timerActive) return;  
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(timer);
          setTimerActive(false);
          return 0; // Ensure it reaches 0 and doesn't go negative
        }
        return prevSeconds - 1;
      });
    }, 1000);
  
    return () => clearInterval(timer);
  }, [timerActive]);

  useEffect(() => {
    if (!timerActive && seconds === 0) {
      navigate('/');  // Trigger navigation only after the timer has expired
    }
  }, [timerActive, seconds, navigate]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    const newCode = [...code];
    
    // Update the code array with the new value
    newCode[index] = value;
    setCode(newCode);
  
    // If the value is not empty and the current index is less than the last index, move focus to the next input field
    if (value !== '' && index < code.length - 1) {
      const nextInput = document.getElementById(`code-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (code[index] === '') {
        if (index > 0) {
          const prevInput = document.getElementById(`code-input-${index - 1}`);
          if (prevInput) {
            prevInput.focus();
          }
        }
      }
    }
  };
  
  const enteredCode = code.join('');
  const handleSubmit = async () => {
    setLoadingAuth(true);
    try {
      const response = await getVerify(enteredCode);
      if (response.error) {
        console.error('Error verifying 2FA:', response.error);
      }
      LoadUserData();
      navigate('/');
    } catch (error) {
      console.error('Error verifying 2FA:', error);
    }finally{
      setLoadingAuth(false);
    }
  };
  
  // add loadUrlAssets
  return (
    <div style={{height: '100vh', width: '100vw' ,background: 'radial-gradient(circle at center,#02006b, #001034)'}}>
      <div className='position-absolute top-50 start-50 translate-middle text-center' style={{ color: 'white' }}>
        <div className='p-1'>
          <i className='bi bi-fingerprint' style={{ fontSize: '80px' }} />
        </div>
        <div className='pb-5 d-flex justify-content-center align-items-center' style={{ height: 60 }}>
          {timerActive && <div style={{ fontSize: '40px' }}>{seconds}</div>}
          {!timerActive && <div style={{ fontSize: '20px' }}>Timer expired!</div>}
        </div>
        <div className="code-inputs">
          {code.map((digit, index) => (
            <input
              key={index}
              id={`code-input-${index}`}
              className="code-input rounded-3 border-0 text-white form-control"
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              style={{
                width: '50px',
                height: '60px',
                fontSize: '24px',
                textAlign: 'center',
                margin: '5px',
                background: '#2f418e',
                display: 'inline-block' // Prevent stacking
              }}
            />
          ))}
        </div>
        <div className='py-3'>6-digit Code is sent to your email</div>
        <div className='text-center mt-2 mb-4'>
          <button
            className="btn"
            type="submit"
            onClick={handleSubmit}
            style={{
              border: 'none',
              marginTop: 20,
              width: 270,
              height: 40,
              color: 'white',
              background: '#d80000',                
            }}>
            Submit Code
          </button>
          <div style={{ fontSize: '14px', marginTop: 10 }}>
            Go to Sign in
            <Link to="/signin" style={{ color: 'red' }}>
              <b> Sign In</b>
            </Link>
          </div>          
        </div>        
      </div>
    </div>
  );
}