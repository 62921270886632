import React from "react";
import './Loader-6.css';

const Loader_6 = () =>{
  return(
    <div className="loader-6">
      <div className="card border-0">
        <div className="loader">
          <p>Scanning for</p>
          <div className="words">
            <span className="word">IP Address</span>
            <span className="word">IPv4</span>
            <span className="word">IPv6</span>
            <span className="word">Subnet Mask</span>
            <span className="word">Gateway</span>
            <span className="word">DNS</span>
            <span className="word">DHCP</span>
            <span className="word">NAT</span>
            <span className="word">CIDR</span>
            <span className="word">Public IP</span>
            <span className="word">Private IP</span>
            <span className="word">MAC Address</span>           
          </div>
        </div>
      </div>
    </div>
  );
}
export default Loader_6;