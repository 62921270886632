import Dashboard from '../modules/s-item-0-Dashboard';
import WebDashboard from '../modules/s-item-1-Dashboard-Web'
import NetworkDashboard from '../modules/s-item-2-Dashboard-Network';
import CloudDashboard from '../modules/s-item-3-Dashboard-Cloud';
import SaveConnect from '../modules/s-item-4-Save-Connect';
import MalwareScanner from '../modules/s-item-6-Malware-Scanner';
import Compliance from '../modules/s-item-7-Compliance';
import Analytics from '../modules/s-item-5-Analytics';
import CyberLaw from '../modules/s-item-9-Cyber-Law';
import Reports from '../modules/s-item-10-Reports';

const menuItems = [
  { id: 0, title: 'Dashboard', activeIcon: 'bi bi-grid-1x2-fill', icon: 'bi bi-grid-1x2', path: '/', component: Dashboard },
  { id: 1, title: 'Web Dashboard', activeIcon: 'bi bi-globe2', icon: 'bi bi-globe', path: '/Web-Dashboard', component: WebDashboard },
  { id: 2, title: 'Network Dashboard', activeIcon: 'bi bi-wifi', icon: 'bi bi-wifi-off', path: '/Network-Dashboard', component: NetworkDashboard },
  { id: 3, title: 'Cloud Dashboard', activeIcon: 'bi bi-clouds-fill', icon: 'bi bi-clouds', path: '/Cloud-Dashboard', component: CloudDashboard },
  { id: 4, title: 'Save Connect', activeIcon: 'bi bi-patch-check-fill', icon: 'bi bi-patch-check', path: '/Save-Connect', component: SaveConnect },
  { id: 5, title: 'Analytics', activeIcon: 'bi bi-pie-chart-fill', icon: 'bi bi-pie-chart', path: '/Analytics', component: Analytics },
  { id: 6, title: 'Malware Scanner', activeIcon: 'bi bi-bug-fill', icon: 'bi bi-bug', path: '/Malware-Scanner', component: MalwareScanner },
  { id: 7, title: 'Compliance', activeIcon: 'bi bi-file-earmark-medical-fill', icon: 'bi bi-file-earmark-medical', path: '/Compliance', component: Compliance },
  { id: 8, title: 'Cyber Law', activeIcon: 'bi bi-shield-fill-check', icon: 'bi bi-shield-check', path: '/Cyber-Law', component: CyberLaw },
  { id: 9, title: 'Reports', activeIcon: 'bi bi-clipboard2-data-fill', icon: 'bi bi-clipboard2-data', path: '/Reports', component: Reports },
];

export default menuItems;