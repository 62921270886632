import React, { useState, useEffect } from "react";
import { useUser } from '../../../context/contextUser';
import { useDesign } from '../../../context/contextDesign';
import { useChat } from '../../../context/contextChat';

const EpssCard = () => {
  const { toggleEpssCard } = useDesign();
  return (
    <div className="profile-card position-absolute top-50 start-50 translate-middle 
        z-3 d-flex py-3 border-0 rounded-4 bg-white shadow-lg"
      style={{ height: 600, width: 1000 }}>
      <div className="card-body d-flex flex-column">
        <TableHeader />
        <div className='scrollbar-all-primary'>
          <CVETable />
        </div>
      </div>
      <div className="position-absolute top-0 end-0 p-4">
        <i className="bi bi-x-lg" 
          style={{ fontSize: 20, cursor: 'pointer' }} onClick={toggleEpssCard} />
      </div>
    </div>
  );
};
export default EpssCard;

const TableHeader = () => {
  const headerStyle = { fontSize: 14, fontWeight: 600, marginBottom: '10px' };
  return (
    <>
      <h4 className="card-title text-center mb-2 mt-2 py-2">CVEs & EPSS</h4>
      <div className="col-12 mb-3 d-flex px-3" style={headerStyle}>
        <div style={{ width: '15%' }}>CVE ID</div>
        <div style={{ width: '10%' }}>EPSS</div>
        <div style={{ width: '10%' }}>Severity</div>
        <div style={{ width: '13%' }}>Exploitability</div>
        <div style={{ width: '8%' }}>Impact</div>
        <div style={{ width: '13%' }}>Confidentiality</div>
        <div style={{ width: '10%' }}>Integrity</div>
        <div style={{ width: '10%' }}>Availability</div>
        <div style={{ width: '10%' }}>Base Score</div>
      </div>
    </>
  );
};

const CVETable = () => {
  const { activeNav } = useDesign();
  const { urlEpssTable, ipEpssTable, cloudEpssTable } = useUser();
  const { setPrompt, setMount } = useChat();
  const { toggleEpssCard } = useDesign();
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const rowStyle = { fontSize: 12, fontWeight: 600, marginBottom: '10px' };
  console.log('activeNav: ', activeNav);
  
  useEffect(() => {
    setVulnerabilities(activeNav == 1 ? urlEpssTable: activeNav == 2 ? ipEpssTable: cloudEpssTable);
  }, []);

  const handleOnClickCVE = async (data) => {
    const formattedData = `
      Give me step by step remediation action plan for the below CVE Details:
      CVE ID: ${data.cveId},
      EPSS: ${Number(data.epss).toFixed(4)},
      Severity: ${data.severity},
      Exploitability: ${Number(data.exploitability).toFixed(2)},
      Impact: ${Number(data.impact).toFixed(2)},
      Confidentiality: ${data.confidentiality},
      Integrity: ${data.integrity},
      Availability: ${data.availability},
      Base Score: ${Number(data.baseScore).toFixed(1)},
      Remediation: ${data.remediation}
    `;

    toggleEpssCard();
    setPrompt(String(formattedData));
  };
  
  return (
    <div className="px-4">
      {vulnerabilities && vulnerabilities.length > 0 ? (
        vulnerabilities
          .sort((a, b) => Number(b.epss) - Number(a.epss)) // Sorting by epss in descending order
          .map((cve, index) => (
            <div className="row py-2 d-flex justify-content-left align-items-center" 
              key={index} style={rowStyle}>
              <div className="badge cve-badge text-bg-secondary py-2"
                data-bs-toggle="modal" data-bs-target="#staticBackdropChatBot"
                style={{ 
                  width: '15%', 
                  cursor: 'pointer',
                  fontSize: 12
                }}
                onClick={async () => {
                  const data = {
                    cveId: cve.cveId,
                    epss: cve.epss,
                    severity: cve.baseSeverity,
                    exploitability: cve.exploitability,
                    impact: cve.impact,
                    confidentiality: cve.confidentiality,
                    integrity: cve.integrity,
                    availability: cve.availability,
                    baseScore: cve.baseScore,
                    remediation: cve.remediation
                  };
                  setMount(true);
                  handleOnClickCVE(data);
                }}>{cve.cveId}</div>
              <div style={{ width: '10%' }}>{Number(cve.epss).toFixed(6)}</div>
              <div style={{ width: '10%' }}>{cve.baseSeverity}</div>
              <div style={{ width: '13%' }}>{Number(cve.exploitability).toFixed(2)}</div>
              <div style={{ width: '8%' }}>{Number(cve.impact).toFixed(2)}</div>
              <div style={{ width: '13%' }}>{cve.confidentiality}</div>
              <div style={{ width: '10%' }}>{cve.integrity}</div>
              <div style={{ width: '10%' }}>{cve.availability}</div>
              <div style={{ width: '10%' }}>{Number(cve.baseScore).toFixed(1)}</div>
            </div>
          ))
      ) : (
        <div className="text-center py-3">No data available</div>
      )}
      <div className="text-center py-3 mb-5">
        <div style={{ width: 220, height: 10 }}/>
      </div>
    </div>
  );
};