import React, { useContext, useState } from 'react';
import { useDesign } from '../context/contextDesign';
import PCIDSSImg from './asset/img/compliance/pci.jpg'
import HIPPAImg from './asset/img/compliance/hipaa.jpg'
import GDPRImg from './asset/img/compliance/gdpr.jpg'
import ISOImg from './asset/img/compliance/iso.jpg'
import NISTCSF2Img from './asset/img/compliance/nist-csf-2.0.jpg'

const buttonsData = [
  {compliance: 'PCI-DSS', image: PCIDSSImg, component: PCIDSS},
  {compliance: 'HIPPA', image: HIPPAImg, component: HIPPA},  
  {compliance: 'ISO 27001', image: ISOImg, component: ISO27001},
  {compliance: 'GDPR', image: GDPRImg, component: GDPRQuestionnaire},
  {compliance: 'NIST-CSF-2.0', image: NISTCSF2Img, component: NistCsfQuestionnaire}
];
const imgStyle = {
  width: '160px', height: '160px',
  objectFit: 'cover',borderRadius: 10,
};
const boxStyle = {width: '230px', height: '400px',
  borderRadius: 20,backgroundColor: '#f6f7f8'
};

const Compliance =({title})=>{
  const { viewHeight, mainBodyWidth } = useDesign();
  const [selectedCard, setSelectedCard] = useState('');
  const handleClick = (card) => {setSelectedCard(card);};
  const props={title, viewHeight, mainBodyWidth, handleClick}
  return(
    <>
      <div className='h-100'>
        <div className='p-1 pb-2'><h4>{title}</h4></div>
        <div className='d-flex justify-content-center align-items-center'>
          {!selectedCard && <ComplianceCard {...props}/>}
        </div>
        <div className='d-flex justify-content-left'>
          <div className='p-1 col-8'>
            <div className="mt-1 scrollbar-all-primary" 
              style={{height: viewHeight, paddingRight: 10}}>
              {selectedCard === 'PCI-DSS' && <PCIDSS {...props}/>}
              {selectedCard === 'HIPPA' && <HIPPA {...props}/>}
              {selectedCard === 'ISO 27001' && <ISO27001 {...props}/> }
              {selectedCard === 'GDPR' && <GDPRQuestionnaire {...props}/> }
              {selectedCard === 'NIST-CSF-2.0' && <NistCsfQuestionnaire {...props}/>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Compliance;


function ComplianceCard({viewHeight, mainBodyHeight, handleClick}){
  return(
    <div className='p-1'>
      <div className='mt-1 scrollbar-all-primary' style={{height: viewHeight}}>
        <div className="row d-flex justify-content-left align-items-center h-100" >
          {buttonsData.map((item, index) => (
            <div className='col-auto'>
              <div className='card py-4 m-2 border-0 col-auto
                d-flex justify-content-between align-items-center shadow' 
                key={index} style={boxStyle}>
                <img className='mt-3 mb-2' src={item.image} alt='' style={imgStyle} />
                <h6>{item.compliance}</h6>
                <button className="border-0 p-2 text-white"
                  onClick={() => handleClick(item.compliance)}
                  style={{width: 180,
                    backgroundColor: '#344266',
                    cursor: 'pointer',
                    borderRadius: 10 }}>Configure
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function PCIDSS(){
  const categories = [
    {
      title: 'General Information',
      questions: [
        '1.	What is the nature of your business?',
        '2.	How many card transactions do you handle annually?',
        '3.	What types of payment cards do you accept?'
      ]
    },
    {
      title: 'PCI DSS Scope Determination',
      questions: [
        '4.	Can you identify all systems that store, process, or transmit cardholder data?',
        '5.	Do you use any third-party service providers for card processing? If so, are they PCI DSS compliant?',
        '6.	Have you defined the boundaries of your cardholder data environment (CDE)?'
      ]
    },
    {
      title: 'Protect Cardholder Data',
      questions: [
        '7.	How do you store cardholder data, and is it encrypted?',
        '8.	What encryption methods are used for cardholder data during transmission over open, public networks?',
        '9.	Do you have any data retention and disposal policies for cardholder data?'
      ]
    },
    {
      title: 'Maintain a Vulnerability Management Program',
      questions: [
        '10.	Do you regularly update anti-virus software or programs on systems that interact with cardholder data?',
        '11.	How frequently do you perform security patches on systems and applications?'
      ]
    },
    {
      title: 'Implement Strong Access Control Measures',
      questions: [
        '12.	How do you manage access to system components in the cardholder data environment?',
        '13.	Do you use multi-factor authentication for access to the cardholder data environment?',
        '14.	What policies do you have in place for unique IDs and secure authentication?'
      ]
    },
    {
      title: 'Regularly Monitor and Test Networks',
      questions: [
        '15.	How often do you review logs and security events for all system components?',
        '16.	Do you perform regular testing of security systems and processes, such as penetration testing and vulnerability scans?'        
      ]
    },
    {
      title: 'Information Security Policy',
      questions: [
        '17.	Do you have a formal security policy that addresses PCI DSS requirements?',
        '18.	How often is the information security policy reviewed and updated?'        
      ]
    },
    {
      title: 'Risk Assessment and Management',
      questions: [
        '19.	Have you conducted a formal risk assessment of the cardholder data environment?',
        '20.	How do you prioritize and address identified risks?'        
      ]
    },
    {
      title: 'Incident Response Plan',
      questions: [
        '21.	Do you have an incident response plan that specifically includes cardholder data breaches?',
        '22.	How is the incident response plan tested and reviewed?'        
      ]
    },
    {
      title: 'PCI DSS Compliance Validation',
      questions: [
        '23.	How do you plan to validate compliance with PCI DSS?',
        '24.	Are you using an internal auditor or a qualified security assessor (QSA) for PCI DSS assessments?'        
      ]
    },
    {
      title: 'Employee Training and Awareness',
      questions: [
        '25.	Do you provide PCI DSS training to employees upon hiring and at least annually thereafter?',
        '26.	How do you monitor and ensure that employees follow security policies and procedures?'        
      ]
    }
  ];

  return (
    <div>
      {categories.map((category, index) => (
        <div key={index} className='mb-3 py-3'>
          <h3>{category.title}</h3>
          {category.questions.map((question, qIndex) => (
            <div key={qIndex} className="mb-3 p-1">
              <p>{question}</p>
              <textarea className="form-control" rows="3"/>
            </div>
          ))}
        </div>
      ))}
      <div className='d-flex justify-content-center mb-3  py-5'>
        <a href="/" className="btn btn-primary upgrade-btn border-0 mt-2"
          style={{backgroundColor: '#2f418e', width: 150,}}>
          Submit
        </a>
      </div>
    </div>
  );
}

function HIPPA() {
  const questions = [
    {
      question: 'Which of the following entities are directly covered by HIPAA?',
      options: [
        'Healthcare providers', 
        'Health plan administrators', 
        'Healthcare clearinghouses', 
        'All of the above'
      ],
      correctAnswer: ''
    },
    {
      question: 'Under HIPAA, patients have the right to:',
      options: [
        'Request corrections to their health records', 
        'Obtain a copy of their health records within 30 days', 
        'Receive a notice of privacy practices from their healthcare provider', 
        'All of the above'
      ],
      correctAnswer: ''
    },
    {
      question: 'Which of the following is a requirement of the HIPAA Privacy Rule?',
      options: [
        'Protect all forms of PHI, whether electronic, written, or oral', 
        'Ensure the disclosure of PHI to the least number of people possible', 
        'Obtain written patient consent before any disclosure of PHI',
        'A & B'
      ],
      correctAnswer: ''
    },
    {
      question: 'Which of the following is considered a physical safeguard under the HIPAA Security Rule?',
      options: [
        'Facility access controls', 
        'Encryption of PHI', 
        'Risk analysis and management', 
        'Secure communications'
      ],
      correctAnswer: ''
    },
    {
      question: 'In the event of a breach of unsecured PHI, covered entities must notify:',
      options: [
        'The affected individuals without undue delay, no later than 60 days after discovering the breach', 
        'The Department of Health and Human Services (HHS) annually for breaches affecting fewer than 500 individuals', 
        'Prominent media outlets if the breach affects more than 500 residents of a state or jurisdiction', 
        'All of the above'
      ],
      correctAnswer: ''
    },
    {
      question: 'HIPAA allows the use and disclosure of PHI without patient authorization for:',
      options: [
        'Marketing purposes', 
        'Fundraising activities',
        'Payment processing', 
        'Treatment activities'
      ],
      correctAnswer: ''
    },
    {
      question: 'How often must HIPAA training be provided to employees of covered entities?',
      options: [
        'Upon hiring', 
        'Annually', 
        'When functions or duties affecting PHI are changed', 
        'A & C'
      ],
      correctAnswer: ''
    },
    {
      question: 'What is the first step in performing a HIPAA risk analysis?',
      options: [
        'Implementing security measures to mitigate risk', 
        'Identifying all PHI the entity creates, receives, maintains, or transmits', 
        'Evaluating the likelihood and impact of potential risks to PHI', 
        'Reviewing past incidents of PHI breaches'
      ],
      correctAnswer: ''
    },
    {
      question: 'When are business associate agreements (BAAs) required under HIPAA?',
      options: [
        'Only when business associates handle PHI directly', 
        'When contractors and subcontractors of a covered entity have access to PHI', 
        'At all times when operating within healthcare facilities', 
        'B & B only if the business associate will use or disclose PHI'
      ],
      correctAnswer: ''
    },
    {
      question: 'What is the maximum penalty for HIPAA non-compliance due to willful neglect that is not corrected within the required time frame?',
      options: [
        '$25,000 per violation, with an annual maximum of $1.5 million', 
        '$50,000 per violation, with an annual maximum of $1.5 million', 
        '$100,000 per violation, with no annual maximum', 
        '$1.5 million per violation category, per year'
      ],
      correctAnswer: ''
    },
  ];

  return (
    <div>
      {questions.map((q, index) => (
        <div key={index} className="mb-3 py-3">
          <h5>{q.question}</h5>
          <div className='px-1'>
            {q.options.map((opt, i) => (
              <div key={i} className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" name={`question${index}`} 
                    value={opt} />{opt}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className='d-flex justify-content-center mb-3 py-5'>
        <a href="/" className="btn btn-primary upgrade-btn border-0 mt-2"
          style={{backgroundColor: '#2f418e', width: 150,}}>
          Submit
        </a>
      </div>
    </div>
  );
}

function ISO27001() {
  const questions = [
    {
      question: 'What has your organization identified as internal issues that could impact your ISMS?',
      options: [
        'Technological changes', 
        'Organizational growth and restructuring', 
        'Competitor activities', 
        'All of the above'
      ],
      correctAnswer: ''
    },
    {
      question: 'Which of the following best describes your leadership\'s involvement in ISMS?',
      options: [
        'Leadership provides clear support but is not involved in operational tasks', 
        'Leadership is unaware of ISMS requirements', 
        'Leadership is directly involved in the management and decision-making processes of ISMS', 'Leadership delegates all responsibilities regarding ISMS without oversight'
      ],
      correctAnswer: ''
    },
    {
      question: 'How does your organization handle risks related to information security?',
      options: [
        'Risks are identified but not systematically managed', 
        'Risks are fully identified, assessed, and managed through documented processes', 
        'We have no formal process for managing information security risks', 
        'Risks are occasionally discussed but no formal assessment is conducted'
      ],
      correctAnswer: ''
    },
    {
      question: 'What training do you provide to ensure employees are aware of information security requirements?',
      options: [
        'Annual training on the basics of information security', 
        'Regular training tailored to specific job roles', 
        'No regular training, only induction sessions', 
        'Ongoing training and updates as standards and policies change'
      ],
      correctAnswer: ''
    },
    {
      question: 'What approach does your organization take for assessing information security performance?',
      options: [
        'We do not regularly assess our security performance', 
        'We conduct annual reviews without defined metrics', 
        'We use specific KPIs and regular audits to assess performance', 
        'We occasionally check system logs when issues arise'
      ],
      correctAnswer: ''
    },
    {
      question: 'How frequently does your organization conduct internal audits of its ISMS?',
      options: [
        'Every month', 
        'Once a year',
        'Every two years', 
        'We do not conduct internal audits'
      ],
      correctAnswer: ''
    },
    {
      question: 'What is your procedure for handling identified nonconformities in your ISMS?',
      options: [
        'Nonconformities are logged but not always addressed', 
        'Immediate corrective actions are taken, and results are monitored', 
        'Nonconformities are discussed during annual meetings without formal actions', 
        'We do not have a procedure for handling nonconformities'
      ],
      correctAnswer: ''
    },
    {
      question: 'Which of the following best describes your information security policy documentation?',
      options: [
        'Comprehensive, regularly updated, and communicated to all relevant parties', 
        'Documented but not regularly reviewed or updated', 
        'Not documented, but informal policies are in place', 
        'No formal information security policies exist'
      ],
      correctAnswer: ''
    },
    {
      question: 'How does your organization classify its information and related assets?',
      options: [
        'All assets are classified according to their sensitivity and criticality', 
        'Some assets are classified but not systematically', 
        'Asset classification is planned but not yet implemented', 
        'We do not classify our assets'
      ],
      correctAnswer: ''
    },
    {
      question: 'What is your policy regarding access control to systems and data?',
      options: [
        'Access is strictly controlled based on roles and responsibilities', 
        'Access is generally open but restricted for sensitive information', 
        'We have informal access controls that are not consistently applied', 
        'No specific access controls in place'
      ],
      correctAnswer: ''
    },
  ];

  return (
    <div>
      {questions.map((q, index) => (
        <div key={index} className="mb-3 py-3">
          <h5>{q.question}</h5>
          <div className='px-1'>
            {q.options.map((opt, i) => (
              <div key={i} className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" name={`question${index}`} 
                    value={opt} />{opt}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className='d-flex justify-content-center mb-3  py-5'>
        <a href="/" className="btn btn-primary upgrade-btn border-0 mt-2"
          style={{backgroundColor: '#2f418e', width: 150,}}>
          Submit
        </a>
      </div>
    </div>
  );
}

function GDPRQuestionnaire (){
  const questions = [
    {
      question: 'Which of the following is NOT one of the GDPR\'s key data protection principles?',
      options: ['Data minimization', 'Accountability', 'Transparency', 'Data proliferation'],
      correctAnswer: ''
    },
    {
      question: 'Which right allows individuals to obtain personal data processed by a controller in a structured, commonly used, and machine-readable format?',
      options: ['Right to be forgotten', 'Right to data portability', 'Right to restrict processing', 'Right of access'],
      correctAnswer: ''
    },
    {
      question: 'Under GDPR, consent must be:',
      options: ['Vague and general, to cover as many bases as possible', 'Specific, informed, and freely given', 'Mandatory for all types of personal data processing', 'Obtained through pre-checked boxes'],
      correctAnswer: ''
    },
    {
      question: 'When is it mandatory to appoint a Data Protection Officer (DPO)',
      options: ['Only if the organization is a public authority or body', 'When processing operations require regular and systematic monitoring of data subjects on a large scale', 'All companies must appoint a DPO regardless of size', 'A & B'],
      correctAnswer: ''
    },
    {
      question: 'What is the maximum time frame within which a company must report a data breach to the supervisory authority under GDPR?',
      options: ['72 hours after becoming aware of it', '1 week after becoming aware of it', 'Immediately, without undue delay', 'Within 30 days'],
      correctAnswer: ''
    },
    {
      question: 'When must a Data Protection Impact Assessment (DPIA) be conducted?',
      options: ['For every new project', 'When processing is likely to result in a high risk to the rights and freedoms of natural persons', 'Annually', 'Only if requested by the Data Protection Authority'],
      correctAnswer: ''
    },
    {
      question: 'Which of the following is a mechanism to legally transfer personal data outside the EU under GDPR?',
      options: ['Binding corporate rules', 'Standard contractual clauses', 'Adequacy decisions', 'All of the above'],
      correctAnswer: ''
    },
    {
      question: 'What is the maximum fine that can be imposed for the most serious infringements of GDPR?',
      options: ['€10 million or 2% of annual global turnover', '€20 million or 4% of annual global turnover', '€5 million or 1% of annual global turnover', '€50 million or 10% of annual global turnover'],
      correctAnswer: ''
    },
    {
      question: 'Under GDPR, what is required of processors regarding personal data?',
      options: ['They must have the same obligations as controllers', 'They can process personal data without instructions from the controller', 'They are not responsible for reporting data breaches', 'They must enter into a legal contract with the controller'],
      correctAnswer: ''
    },
    {
      question: 'Which organizations are required to maintain detailed records of processing activities under GDPR?',
      options: ['All organizations, regardless of size', 'Organizations with more than 250 employees', 'Organizations that process sensitive personal data on a large scale', 'B & C'],
      correctAnswer: ''
    },
  ];

  return (
    <div>
      {questions.map((q, index) => (
        <div key={index} className="mb-3 py-3">
          <h5>{q.question}</h5>
          <div className='px-1'>
            {q.options.map((opt, i) => (
              <div key={i} className="form-check">
                <label className="form-check-label">
                  <input className="form-check-input" type="radio" name={`question${index}`} 
                    value={opt} />{opt}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className='d-flex justify-content-center mb-3  py-5'>
        <a href="/" className="btn btn-primary upgrade-btn border-0 mt-2"
          style={{backgroundColor: '#2f418e', width: 150,}}>
          Submit
        </a>
      </div>
    </div>
  );
};

function NistCsfQuestionnaire (){
  const categories = [
    {
      title: 'Identify',
      questions: [
        'What are your organization\'s most critical assets and data?',
        'How does your organization identify and prioritize cybersecurity risks?',
        'How does your organization manage access to critical assets and data?'
      ]
    },
    {
      title: 'Protect',
      questions: [
        'What measures does your organization have in place to protect against unauthorized access?',
        'How does your organization protect data at rest and in transit?',
        'How are security policies and procedures communicated and enforced?'
      ]
    },
    {
      title: 'Detect',
      questions: [
        'How does your organization detect cybersecurity events?',
        'What monitoring tools and techniques are used to identify potential threats?',
        'How quickly can your organization detect and respond to cybersecurity incidents?'
      ]
    },
    {
      title: 'Respond',
      questions: [
        'What is your organization\'s incident response plan?',
        'How are cybersecurity incidents reported and escalated within your organization?',
        'How does your organization communicate with stakeholders during a cybersecurity incident?'
      ]
    },
    {
      title: 'Recover',
      questions: [
        'What measures does your organization have in place to recover from a cybersecurity incident?',
        'How quickly can your organization restore normal operations after a cybersecurity incident?',
        'What lessons learned are documented and incorporated into future cybersecurity practices?'
      ]
    }
  ];

  return (
    <div>
      {categories.map((category, index) => (
        <div key={index} className='mb-3 py-3'>
          <h3>{category.title}</h3>
          {category.questions.map((question, qIndex) => (
            <div key={qIndex} className="mb-3 p-1">
              <p>{question}</p>
              <textarea className="form-control" rows="3"/>
            </div>
          ))}
        </div>
      ))}
      <div className='d-flex justify-content-center mb-3  py-5'>
        <a href="/" className="btn btn-primary upgrade-btn border-0 mt-2"
          style={{backgroundColor: '#2f418e', width: 150,}}>
          Submit
        </a>
      </div>
    </div>
  );
};

