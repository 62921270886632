import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useUser } from './context/contextUser';
import { useDesign } from './context/contextDesign';

import './modules/css/overall-style.css';
import './modules/css/overall-specialFx.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-circular-progressbar/dist/styles.css';

import Main from './modules/Main';


function App() {
  
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;