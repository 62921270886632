import React from "react";
import './Loader-5.css';

const Loader_5 = () =>{
  return(
    <div className="loader-5">
      <div className="card border-0">
        <div className="loader">
          <p>Scanning for</p>
          <div className="words">
            <span className="word">Injection</span>
            <span className="word">Open Redirect</span>
            <span className="word">Tampering</span>
            <span className="word">XSS</span>
            <span className="word">CSRF</span>
            <span className="word">IDOR</span> 
            <span className="word">SSRF</span>
            <span className="word">Splitting</span>
            <span className="word">Directory</span>
            <span className="word">Session Fixation</span>
            <span className="word">Phishing URL</span>
            <span className="word">Traversal</span>            
          </div>
        </div>
      </div>
    </div>
  );
}
export default Loader_5;