import React, { useContext } from 'react';
import { useUser } from '../../context/contextUser';
import { useDesign } from '../../context/contextDesign';
import Searchbar from './search-bar/search-bar';
import ExternalLinks from './r-2-external-link';
import BellIcon from './r-1-bell-icon';
import ProfileSmall from './r-3-profile';

const Navbar = ({handleSignOut}) => {
  const { isSidebarExtended } = useUser();
  const {
    activeNav, setActiveNav,
    mainBodyHeight, mainBodyWidth,
    sidebarWidthVal, toggleSidebar,
    FadingComponent,
  } = useDesign();

  const props = {handleSignOut}
  return (
    <>
      <nav className="navbar navbar-dark fixed-top"
        style={{
          marginLeft: sidebarWidthVal,
          width: mainBodyWidth,
          height: 'auto',
          transition: 'all 0.2s',
          }}>
        <div className="w-100">
          <div className="row d-flex justify-content-center align-items-center py-1">
            <div className="col-auto px-4">
              <button
                className="navbar-toggler border-0"
                type="button"
                onClick={toggleSidebar}
                aria-controls="sidebarCollapse"
                aria-expanded={isSidebarExtended ? "true" : "false"}
                aria-label="Toggle sidebarExtended">
                <span className="navbar-toggler-icon" />
              </button>
            </div>
            <div className="col text-center">
              <Searchbar />
            </div>
            <div className="col-auto px-5 mx-4">
              <div className="row">
                <div className="col"><BellIcon {...props}/></div>
                <div className="col"><ExternalLinks {...props}/></div>
                <div className="col"><ProfileSmall {...props} /></div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
