import React from "react";
import './Loader-3.css';

const Loader_3 = () =>{
  return(
    <div className="position-absolute top-50 start-50 translate-middle">
      <div className="loader-3"></div>
    </div>
  );
}
export default Loader_3;