import React, { useContext, useRef, useEffect, useState } from 'react';
import { DesignContext } from '../../../context/contextDesign';
import PricingPlan from '../../asset/img/card/Pricing-Plan.gif'

const PricingSection = () => {
  const { toggleUpgradeCard } = useContext(DesignContext);
  const btnSyle = {background: "#E1EAFF", width: 130, height: 34,};
  const btnClass = 'px-3 mx-2 btn btn-primary border-0 text-dark fw-bold'
  return (
    <>
      <div className="profile-card position-absolute top-50 start-50 translate-middle 
      z-3 border-0 rounded-4 d-flex justify-content-center shadow-lg" 
        style={{background: '#ffffff'}}>
        <section className="pricing-section">
          <div>
            <div className='d-flex flex-row 
              position-absolute position-absolute bottom-0 end-0 px-4'
              style={{paddingBottom: 37}}>
              <button className={btnClass} style={btnSyle}>$19/month</button>
              <button className={btnClass} style={btnSyle}>$159/month</button>
              <button className={btnClass} style={btnSyle}>$1199/month</button>
            </div>
            <img className='rounded-4' src={PricingPlan} alt="" />
          </div>
        </section>
        <div className="position-absolute top-0 end-0 p-4 text-white" style={{fontSize: 20}}>
          <i className="bi bi-x-lg" onClick={toggleUpgradeCard}/>
        </div>
      </div>
      <div className="z-2 profile-card position-absolute
      top-50 start-50 translate-middle vw-100 vh-100"
      style={{background: '#34343466'}}/>
    </>
  );
};

export default PricingSection;