import React from "react";

const text='We detected suboptimal or unsafe practices on your website Please see the list below for more details'

const SecurityAuditResult=({setText})=>{
  return(
    <div className="pe-2 mb-2">
      <div className="card rounded-4"
        style={{ width: 350, height: 167 }}>
        <div className="card-body text-left">
          <div className="p-2">
            <div className="card-title" style={{fontSize: '22px'}}>
              Security Audit Results
            </div>           
            <p className="card-title"
              style={{fontWeight: 400, fontSize: 13}}>{setText}
            </p> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecurityAuditResult;