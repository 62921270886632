export const generateProfilePic = (text) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');    
  // Set canvas dimensions
  canvas.width = 100;
  canvas.height = 100;
  // Background color (you can change this to any color you prefer)
  context.fillStyle = '#007bff';
  context.fillRect(0, 0, canvas.width, canvas.height);    
  // Text color
  context.fillStyle = '#ffffff';
  context.font = 'bold 50px Arial';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  // Draw the first letter of the company name
  context.fillText(text.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2);
  
  // Return the data URL of the image
  return canvas.toDataURL('image/png');
};