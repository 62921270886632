import React from "react";

const NotFound404 =()=>{
  return(
    <>
      <div className="position-absolute top-50 start-50 translate-middle">        
        <center>
          <h1>404 - Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
        </center>
      </div>      
    </>
  )
}

export default NotFound404;


