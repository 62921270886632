import React from 'react';
import { useChat } from '../../../context/contextChat';

export const isCyberSecurityRelated = async (input) => {
  if (!input) {
    console.log('Input is empty');
    return null; // Explicitly return null for empty input
  }
  try {
    const result = keywords.some(keyword => 
      input.toLowerCase().includes(keyword.toLowerCase())
    );
    console.log(`Input: "${input}" - Is Cybersecurity Related: ${result}`);
    return result; // Return true/false based on the match
  } catch (error) {
    console.error(error);
  }
  return false; // Fallback in case of any error
};

const keywords = [
  // General Cybersecurity Terms
  'cybersecurity', 'information security', 'infosec', 'security', 'cyber attack', 'cyber defense', 'hacking', 'ethical hacking',
  'penetration testing', 'pentest', 'cybercrime', 'cyber threat', 'exploit', 'vulnerability', 'CVE', 'zero-day', 'zero day',

  // Threats
  'malware', 'ransomware', 'spyware', 'trojan', 'worm', 'virus', 'adware', 'rootkit', 'botnet', 'phishing', 'spear phishing',
  'social engineering', 'brute force', 'DDoS', 'DoS', 'SQL injection', 'XSS', 'cross-site scripting', 'session hijacking',
  'man-in-the-middle', 'mitm', 'buffer overflow', 'cryptojacking', 'password attack', 'keylogger',

  // Security Practices and Concepts
  'firewall', 'intrusion detection', 'intrusion prevention', 'IDS', 'IPS', 'SIEM', 'endpoint protection', 'vulnerability management',
  'incident response', 'patch management', 'risk assessment', 'threat modeling', 'attack surface', 'encryption', 'decryption',
  'data breach', 'data leakage', 'access control', 'multi-factor authentication', 'MFA', '2FA', 'authorization', 'authentication',
  'tokenization', 'hashing', 'cyber hygiene', 'privilege escalation', 'password policy',

  // Security Protocols and Standards
  'TLS', 'SSL', 'HTTPS', 'IPSec', 'SSH', 'PGP', 'OAuth', 'SAML', 'FIDO', 'ISO 27001', 'NIST', 'GDPR', 'PCI-DSS', 'HIPAA', 'FERPA',
  'CIS controls', 'OWASP', 'SOC 2', 'CMMC', 'FISMA',

  // Network and Systems Security
  'network security', 'endpoint security', 'application security', 'cloud security', 'container security', 'server security',
  'database security', 'IoT security', 'mobile security', 'email security', 'VPN', 'zero trust', 'ZTA', 'segmentation', 'network access control',
  'microsegmentation', 'DNS security', 'network monitoring', 'log monitoring', 'packet sniffing', 'deep packet inspection',
  'threat intelligence', 'SOC', 'NOC', 'forensics', 'cyber forensics', 'reverse engineering', 'honeypot',

  // Tools and Technologies
  'Wireshark', 'Metasploit', 'Nmap', 'Burp Suite', 'OWASP ZAP', 'Nessus', 'Qualys', 'Kali Linux', 'Snort', 'Suricata', 'Splunk', 
  'Tenable', 'OpenVAS', 'Aircrack-ng', 'John the Ripper', 'Hydra', 'Hashcat', 'Shodan', 'Cuckoo Sandbox', 'ELK Stack', 'Graylog',
  
  // Cryptography
  'cryptography', 'AES', 'RSA', 'SHA-256', 'SHA-512', 'public key', 'private key', 'symmetric encryption', 'asymmetric encryption',
  'digital certificate', 'digital signature', 'PKI', 'blockchain', 'cryptanalysis',

  // Cloud and Virtualization Security
  'cloud security', 'AWS security', 'Azure security', 'GCP security', 'Kubernetes security', 'container security', 'VM security', 
  'virtualization security', 'hypervisor security', 'serverless security', 'cloud compliance',

  // Compliance and Regulations
  'compliance', 'regulation', 'cyber law', 'GDPR', 'HIPAA', 'CCPA', 'SOX', 'FERPA', 'FISMA', 'SOC 2', 'PCI DSS', 'CMMC', 'data privacy',
  'data protection', 'BSA', 'financial security', 'cyber insurance',

  // Cybersecurity Roles and Certifications
  'CISO', 'CIO', 'SOC analyst', 'security engineer', 'security architect', 'forensic analyst', 'cybersecurity analyst', 'ethical hacker',
  'red team', 'blue team', 'purple team', 'certification', 'CISSP', 'CEH', 'CISM', 'CompTIA Security+', 'OSCP', 'GIAC', 'GSEC', 'CHFI', 'ISO 27001 lead auditor',

  // Miscellaneous
  'APT', 'advanced persistent threat', 'cyber espionage', 'bug bounty', 'zero trust architecture', 'ZTNA', 'threat actor', 'kill chain', 'attack vector',
  'threat landscape', 'dark web', 'deep web', 'cyber resilience', 'disaster recovery', 'business continuity', 'threat hunting', 'insider threat', 'cyber warfare'
];