const popperIcons = [
  { name: 'Facebook', iconClass: 'bi bi-facebook' },
  { name: 'Twitter', iconClass: 'bi bi-twitter' },
  { name: 'LinkedIn', iconClass: 'bi bi-linkedin' },
  { name: 'Instagram', iconClass: 'bi bi-instagram' },
  { name: 'YouTube', iconClass: 'bi bi-youtube' },
  { name: 'GitHub', iconClass: 'bi bi-github' },
  { name: 'Twitch', iconClass: 'bi bi-twitch' },
  { name: 'Snapchat', iconClass: 'bi bi-snapchat' },
  { name: 'Reddit', iconClass: 'bi bi-reddit' },
  { name: 'WhatsApp', iconClass: 'bi bi-whatsapp' },
  { name: 'Discord', iconClass: 'bi bi-discord' },
  { name: 'Pinterest', iconClass: 'bi bi-pinterest' },
  { name: 'Skype', iconClass: 'bi bi-skype' },
  { name: 'Vimeo', iconClass: 'bi bi-vimeo' },
  { name: 'Dribbble', iconClass: 'bi bi-dribbble' },
  { name: 'Behance', iconClass: 'bi bi-behance' },
  { name: 'Git', iconClass: 'bi bi-git' },
  { name: 'Flickr', iconClass: 'bi bi-camera' },
  { name: 'Spotify', iconClass: 'bi bi-spotify' },
  { name: 'Steam', iconClass: 'bi bi-steam' },
];

export default popperIcons;