import React, { useState } from "react";

const Solutions=({setText})=>{
  const [modalContent, setModalContent] = useState('');

  const handleSolutionReadMore = (content) => {
    setModalContent(content);
    const modal = new window.bootstrap.Modal(document.getElementById('staticBackdropSolution'));
    modal.show();  // Show the modal   
  };

  const truncateText = (text, wordLimit = 20) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'; // Truncate and add ellipsis
    }
    return text;
  };

  return(
    <>
      <div className="pe-2 mb-2">
        <div className="card rounded-4"
          style={{ width: 350, height: 167 }}>
          <div className="card-body text-left">
            <div className="p-2">
              <div className="card-title" style={{fontSize: '22px'}}>
                Solutions
              </div>           
              <p className="card-title"
                style={{fontWeight: 400, fontSize: 13}}>
                {truncateText(setText, 20)}{' '}
                {setText.split(' ').length > 20 && (
                  <button
                    onClick={() => handleSolutionReadMore(setText)}
                    style={{ color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                    Read More
                  </button>
                )}
              </p> 
            </div>
          </div>
        </div>
        <div className=''>
          <div className="modal fade" id="staticBackdropSolution" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
              <div className="modal-content bg-dark text-white">
                <div className="modal-header" style={{borderBottom: '1px solid #444444'}}>
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Full Content</h1>
                  <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  {modalContent}
                </div>
                <div className="modal-footer" style={{ borderTop: '1px solid #444444' }}>
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Solutions;