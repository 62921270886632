import React, { useContext, useRef, useEffect, useState } from 'react';
import { useAuth } from '../../context/contextAuth';
import { useUser } from '../../context/contextUser';
import popperIcons from '../../config/popperIcons';

const ExternalLinksPopup = () => {
  const { upgradePlan } = useAuth();
  const [showIcons, setShowIcons] = useState(false);
  const dropdownRef = useRef(null);
  const isUpgraded = upgradePlan;

  const handleClick = () => {
    setShowIcons(!showIcons);
  };
  
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)){
      setShowIcons(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="dropdown">
      <div>
        <button className="btn btn-secondary bg-transparent border-0"
          type="button" onClick={handleClick}>
          <i className="bi bi-grid-3x3-gap-fill"/>
        </button>
      </div>
      {showIcons && <ShowFlexboxIcons isUpgraded={isUpgraded}/>}
    </div>
  );
};
export default ExternalLinksPopup;

const ShowFlexboxIcons = () => {
  const { upgradePlan } = useAuth();
  const isUpgraded = upgradePlan;
  const widgetCardBGColor='rgb(52 66 102)';
  const widgetAreaStyleColor='#17234D';
  const widgetCardStyle={
    marginTop: 3, marginBottom: 3, marginRight: -6,         
    background: widgetAreaStyleColor,
    border: `solid 4px ${widgetCardBGColor}`,
  };
  const widgetBtnIcon={height: 90, width: 90, background: widgetAreaStyleColor}
  const widgetBtnSubscription={
    width: 200, height: 60, 
    background: widgetAreaStyleColor,
    fontSize: 20, fontWeight: 300,
  };
    
  return(
    <div className="row justify-content-center externalLinkRowRapper 
      scrollbar-externalLinksPopup position-absolute rounded-5 shadow-lg"
      style={{ width: 360, height: 440,
        right: -96, top: 40,            
        background: widgetCardBGColor,
        transition: 'all 0.2s',          
      }}>
      <div className="d-flex justify-content-center align-items-center 
        w-100 py-3 mb-0 text-center text-white rounded-5"
        style={{...widgetCardStyle, height: 60}}>
          <span style={{fontSize: '22px', fontWeight: 300}}>
            3rd Party Tools for <span style={{color: 'red'}}>Enterprise</span>
          </span>
      </div>
      <div className="d-flex justify-content-center w-100 py-3 rounded-5" style={{ ...widgetCardStyle, height: isUpgraded? 880: 240 }}>
        {isUpgraded && (
            <div className='d-flex justify-content-center'>
              <div className='row p-3'>
                {popperIcons.map((icon, index) => (
                  <div key={index} className="col-4 d-flex justify-content-center">
                    <div className="button text-center my-1 widgetBtnIcon">
                      <button 
                        className="btn btn-dark text-white col-12 border-0 rounded-4" 
                        type="button" style={widgetBtnIcon}>
                        <i className={icon.iconClass} style={{ fontSize: 33 }} />
                        <p className='mb-0' style={{ fontSize: 13 }}>{icon.name}</p>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!isUpgraded && (
            <div className='h3 d-flex justify-content-center align-items-center text-white fw-light'>
              Feature Coming Soon
            </div>
          )}

      </div>
      {!isUpgraded && (
        <div className="d-flex justify-content-center align-items-center 
          w-100 py-3 text-center text-white mb-3"
          style={{height: 100, }}>
            <button type="button" className="btn btn-primary border-0 rounded-5" 
              style={widgetBtnSubscription}>
              Get Pro Acess
            </button>
        </div>
      )}
    </div>
  )
}