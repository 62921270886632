// import ChatBot from './components/ChatBot/ChatBot';
import React, { useState, useRef, useEffect, useContext } from "react";
import WebDashboard from "./s-item-1-Dashboard-Web";
import Loader_1 from "./components/Loaders/Loader-1/Loader-1";
import Loader_2 from "./components/Loaders/Loader-2/Loader-2";
import Loader_3 from "./components/Loaders/Loader-3/Loader-3";
import Loader_4 from "./components/Loaders/Loader-4/Loader-4";
import Loader_5 from "./components/Loaders/Loader-5/Loader-5";
import Loader_6 from "./components/Loaders/Loader-6/Loader-6";
import Loader_7 from "./components/Loaders/Loader-7/Loader-7";

const Test = () =>{
  return(
    <>
      <Loader_7 />
    </>
  );
};

export default Test;

