import GCloudIcon from '../modules/asset/img/save-connect/1-google-cloud.png';
import AzureIcon from '../modules/asset/img/save-connect/2-azure.png';
import AWSIcon from '../modules/asset/img/save-connect/3-aws.png';

const cloudItems = [
  {
    src: AWSIcon,
    title: 'AWS',
    alt: 'AWS Logo',
  },{
    src: AzureIcon,
    title: 'Azure',
    alt: 'Azure Logo',
  },{
    src: GCloudIcon,
    title: 'GCloud',
    alt: 'GCloud Logo',
  },
];

export default cloudItems;