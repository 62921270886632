import React, { useContext, useRef, useEffect, useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import securityToolsItems from '../config/securityToolsItems';
import cloudItems from '../config/cloudItems';

import { 
  ResponsiveContainer, CartesianGrid, Bar, BarChart,
  LineChart, XAxis, YAxis, Line, Tooltip, Legend
} from 'recharts';

const tabs = [
  { id: 'WebAnalytics', label: 'Web Analytics' },
  { id: 'NetworkAnalytics', label: 'Network Analytics' },
  { id: 'CloudAnalytics', label: 'Cloud Analytics' },
  { id: 'SaveConnectAnalytics', label: 'Save Connect' },
];

export default function AnalyticsView({title}) {
  const [selectedTab, setSelectedTab] = useState('WebAnalytics');
  const [selectedCloud, setSelectedCloud] = useState('GCloud');
  const [selectedSecTools, setSelectedSecTools] = useState('Crowdstrike');
  const [selectedDuration, setSelectedDuration] = useState('Last 7 days');
  const [TabData, setTabData] = useState([]);
  const [dataBreachRecord, setDataBreachRecord] = useState([]);
  const [webGraphData, setWebGraphData] = useState([]);
  const [networkGraphData, setNetworkGraphData] = useState([]);
  const [GCloudGraphData, setGCloudGraphData] = useState([]);
  const [AWSGraphData, setAWSGraphData] = useState([]);
  const [AzureGraphData, setAzureGraphData] = useState([]);
  const [CrowdstrikeGraphData, setCrowdstrikeGraphData] = useState([]);
  const [WiresharkGraphData, setWiresharkGraphData] = useState([]);
  const [AstraInternationalGraphData, setAstraInternationalGraphData] = useState([]);
  const [QualysGraphData, setQualysGraphData] = useState([]);
  const [EttercapGraphData, setEttercapGraphData] = useState([]);
  const [EGOGraphData, setEGOGraphData] = useState([]);
  const [SolarWindsGraphData, setSolarWindsGraphData] = useState([]);
  const [BreachlockGraphData, setBreachlockGraphData] = useState([]);
  const [BalbixGraphData, setBalbixGraphData] = useState([]);
  const [SkyboxGraphData, setSkyboxGraphData] = useState([]);
  const [IndusfaceGraphData, setIndusfaceGraphData] = useState([]);
  const [SNORTGraphData, setSNORTGraphData] = useState([]);
  const [GFILanGuardGraphData, setGFILanGuardGraphData] = useState([]);
  const [VERACODEGraphData, setVERACODEGraphData] = useState([]);
  const [HolmSecurityGraphData, setHolmSecurityGraphData] = useState([]);
  const [TripwireGraphData, setTripwireGraphData] = useState([]);
  const [RapidGraphData, setRapidGraphData] = useState([]);
  const [INTRUDERGraphData, setINTRUDERGraphData] = useState([]);
  const [SalesforceGraphData, setSalesforceGraphData] = useState([]);
  const [TenableGraphData, setTenableGraphData] = useState([]);

  const { user, upgradePlan } = useAuth();
  const {
    viewHeight, toggleUpgradeCard,
    mainBodyHeight, mainBodyWidth,
  } = useDesign();  
  
  const setGraphHeight='90%';
  const setGraphWidth='100%';
  const navigationTab = {
    borderRadius: '10px 10px 0px 0px',
    cursor: 'pointer',
    color: 'black'
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbreach_rec_res = await fetch(data_breach_record);
        const web_res = await fetch(web_analytics_graph_data);
        const nt_res = await fetch(network_analytics_graph_data);
        // Cloud Services
        const GCloud_res = await fetch(GCloud_graph_data);
        const AWSCloud_res = await fetch(AWSCloud_graph_data);
        const AzureCloud_res = await fetch(AzureCloud_graph_data);
        // Security Tools
        const Crowdstrike_res = await fetch(Crowdstrike_graph_data);
        const Wireshark_res = await fetch(Wireshark_graph_data);
        const AstraInternational_res = await fetch(AstraInternational_graph_data);
        const Qualys_res = await fetch(Qualys_graph_data);
        const Ettercap_res = await fetch(Ettercap_graph_data);
        const EGO_res = await fetch(EGO_graph_data);
        const SolarWinds_res = await fetch(SolarWinds_graph_data);
        const Breachlock_res = await fetch(Breachlock_graph_data);
        const Balbix_res = await fetch(Balbix_graph_data);
        const Skybox_res = await fetch(Skybox_graph_data);
        const Indusface_res = await fetch(Indusface_graph_data);
        const SNORT_res = await fetch(SNORT_graph_data);
        const GFILanGuard_res = await fetch(GFILanGuard_graph_data);
        const VERACODE_res = await fetch(VERACODE_graph_data);
        const HolmSecurity_res = await fetch(HolmSecurity_graph_data);
        const Tripwire_res = await fetch(Tripwire_graph_data);
        const Rapid_res = await fetch(Rapid_graph_data);
        const INTRUDER_res = await fetch(INTRUDER_graph_data);
        const Salesforce_res = await fetch(Salesforce_graph_data);
        const Tenable_res = await fetch(Tenable_graph_data);


        const dbreach_rec_data = await dbreach_rec_res.json();
        const web_data = await web_res.json();
        const nt_data = await nt_res.json();   
        // Cloud Services
        const GCloud_data = await GCloud_res.json();
        const AWSCloud_data = await AWSCloud_res.json();
        const AzureCloud_data = await AzureCloud_res.json();
        // Security Tools
        const Crowdstrike_data = await Crowdstrike_res.json();
        const Wireshark_data = await Wireshark_res.json();
        const AstraInternational_data = await AstraInternational_res.json();
        const Qualys_data = await Qualys_res.json();
        const Ettercap_data = await Ettercap_res.json();
        const EGO_data = await EGO_res.json();
        const SolarWinds_data = await SolarWinds_res.json();
        const Breachlock_data = await Breachlock_res.json();
        const Balbix_data = await Balbix_res.json();
        const Skybox_data = await Skybox_res.json();
        const Indusface_data = await Indusface_res.json();
        const SNORT_data = await SNORT_res.json();
        const GFILanGuard_data = await GFILanGuard_res.json();
        const VERACODE_data = await VERACODE_res.json();
        const HolmSecurity_data = await HolmSecurity_res.json();
        const Tripwire_data = await Tripwire_res.json();
        const Rapid_data = await Rapid_res.json();
        const INTRUDER_data = await INTRUDER_res.json();
        const Salesforce_data = await Salesforce_res.json();
        const Tenable_data = await Tenable_res.json();       


        setDataBreachRecord(dbreach_rec_data);
        setWebGraphData(web_data);
        setNetworkGraphData(nt_data);

        setGCloudGraphData(GCloud_data);
        setAWSGraphData(AWSCloud_data);
        setAzureGraphData(AzureCloud_data);

        setCrowdstrikeGraphData(Crowdstrike_data);
        setWiresharkGraphData(Wireshark_data);
        setAstraInternationalGraphData(AstraInternational_data);
        setQualysGraphData(Qualys_data);
        setEttercapGraphData(Ettercap_data);
        setEGOGraphData(EGO_data);
        setSolarWindsGraphData(SolarWinds_data);
        setBreachlockGraphData(Breachlock_data);
        setBalbixGraphData(Balbix_data);
        setSkyboxGraphData(Skybox_data);
        setIndusfaceGraphData(Indusface_data);
        setSNORTGraphData(SNORT_data);
        setGFILanGuardGraphData(GFILanGuard_data);
        setVERACODEGraphData(VERACODE_data);
        setHolmSecurityGraphData(HolmSecurity_data);
        setTripwireGraphData(Tripwire_data);
        setRapidGraphData(Rapid_data);
        setINTRUDERGraphData(INTRUDER_data);
        setSalesforceGraphData(Salesforce_data);
        setTenableGraphData(Tenable_data);        
        //Add more

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }; fetchData();
  }, []);

  useEffect(() => {
    if (selectedDuration && selectedTab) {
      if (selectedTab === 'DataBreach') 
        setTabData(processData(dataBreachRecord, selectedDuration));
      else if (selectedTab === 'WebAnalytics')
        setTabData(processData(webGraphData, selectedDuration));
      else if (selectedTab === 'NetworkAnalytics')
        setTabData(processData(networkGraphData, selectedDuration));

      else if (selectedTab === 'CloudAnalytics'){
        if(selectedCloud === 'GCloud')
          setTabData(processData(GCloudGraphData, selectedDuration));
        else if (selectedCloud === 'AWSCloud')
          setTabData(processData(AWSGraphData, selectedDuration));
        else if (selectedCloud === 'AzureCloud')
          setTabData(processData(AzureGraphData, selectedDuration));
      }
      else if (selectedTab === 'SaveConnectAnalytics'){
        if (selectedSecTools === 'Crowdstrike')
          setTabData(processData(CrowdstrikeGraphData, selectedDuration));
        else if (selectedSecTools === 'Wireshark')
          setTabData(processData(WiresharkGraphData, selectedDuration));
        else if (selectedSecTools === 'Astra International')
          setTabData(processData(AstraInternationalGraphData, selectedDuration));
        else if (selectedSecTools === 'Qualys')
          setTabData(processData(QualysGraphData, selectedDuration));
        else if (selectedSecTools === 'Ettercap')
          setTabData(processData(EttercapGraphData, selectedDuration));
        else if (selectedSecTools === 'EGO')
          setTabData(processData(EGOGraphData, selectedDuration));
        else if (selectedSecTools === 'SolarWinds')
          setTabData(processData(SolarWindsGraphData, selectedDuration));
        else if (selectedSecTools === 'Breachlock')
          setTabData(processData(BreachlockGraphData, selectedDuration));
        else if (selectedSecTools === 'Balbix')
          setTabData(processData(BalbixGraphData, selectedDuration));
        else if (selectedSecTools === 'Skybox')
          setTabData(processData(SkyboxGraphData, selectedDuration));
        else if (selectedSecTools === 'Indusface')
          setTabData(processData(IndusfaceGraphData, selectedDuration));
        else if (selectedSecTools === 'SNORT')
          setTabData(processData(SNORTGraphData, selectedDuration));
        else if (selectedSecTools === 'GFILanGuard')
          setTabData(processData(GFILanGuardGraphData, selectedDuration));
        else if (selectedSecTools === 'VERACODE')
          setTabData(processData(VERACODEGraphData, selectedDuration));
        else if (selectedSecTools === 'Holm Security')
          setTabData(processData(HolmSecurityGraphData, selectedDuration));
        else if (selectedSecTools === 'Tripwire')
          setTabData(processData(TripwireGraphData, selectedDuration));
        else if (selectedSecTools === 'Rapid')
          setTabData(processData(RapidGraphData, selectedDuration));
        else if (selectedSecTools === 'INTRUDER')
          setTabData(processData(INTRUDERGraphData, selectedDuration));
        else if (selectedSecTools === 'Salesforce')
          setTabData(processData(SalesforceGraphData, selectedDuration));
        else if (selectedSecTools === 'Tenable')
          setTabData(processData(TenableGraphData, selectedDuration));
      }
      //Add more
    }
  }, [selectedDuration, selectedTab, selectedCloud, selectedSecTools,
    dataBreachRecord, webGraphData, networkGraphData]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
  };
  const handleCloudBtnClk = (button) => {
    setSelectedCloud(button.title);
    if(button.title === 'GCloud')
      setTabData(processData(GCloudGraphData, selectedDuration));
    else if (button.title === 'AWSCloud')
      setTabData(processData(AWSGraphData, selectedDuration));
    else if (button.title === 'AzureCloud')
      setTabData(processData(AzureGraphData, selectedDuration));
    // console.log('You clicked on' + button.title);
  };
  const handleSecToolBtnClk = (button) => {
    setSelectedSecTools(button.title);
    if (button.title === 'Crowdstrike')
      setTabData(processData(CrowdstrikeGraphData, selectedDuration));
    else if (button.title === 'Wireshark')
      setTabData(processData(WiresharkGraphData, selectedDuration));
    else if (button.title === 'Astra International')
      setTabData(processData(AstraInternationalGraphData, selectedDuration));
    else if (button.title === 'Qualys')
      setTabData(processData(QualysGraphData, selectedDuration));
    else if (button.title === 'Ettercap')
      setTabData(processData(EttercapGraphData, selectedDuration));
    else if (button.title === 'EGO')
      setTabData(processData(EGOGraphData, selectedDuration));
    else if (button.title === 'SolarWinds')
      setTabData(processData(SolarWindsGraphData, selectedDuration));
    else if (button.title === 'Breachlock')
      setTabData(processData(BreachlockGraphData, selectedDuration));
    else if (button.title === 'Balbix')
      setTabData(processData(BalbixGraphData, selectedDuration));
    else if (button.title === 'Skybox')
      setTabData(processData(SkyboxGraphData, selectedDuration));
    else if (button.title === 'Indusface')
      setTabData(processData(IndusfaceGraphData, selectedDuration));
    else if (button.title === 'SNORT')
      setTabData(processData(SNORTGraphData, selectedDuration));
    else if (button.title === 'GFILanGuard')
      setTabData(processData(GFILanGuardGraphData, selectedDuration));
    else if (button.title === 'VERACODE')
      setTabData(processData(VERACODEGraphData, selectedDuration));
    else if (button.title === 'Holm Security')
      setTabData(processData(HolmSecurityGraphData, selectedDuration));
    else if (button.title === 'Tripwire')
      setTabData(processData(TripwireGraphData, selectedDuration));
    else if (button.title === 'Rapid')
      setTabData(processData(RapidGraphData, selectedDuration));
    else if (button.title === 'INTRUDER')
      setTabData(processData(INTRUDERGraphData, selectedDuration));
    else if (button.title === 'Salesforce')
      setTabData(processData(SalesforceGraphData, selectedDuration));
    else if (button.title === 'Tenable')
      setTabData(processData(TenableGraphData, selectedDuration));    
  }

  const processData = (data, period) => {
    const now = new Date();
    let startDate, endDate;
    endDate = new Date(now);
  
    switch (period) {
      case 'Last 7 days':
        startDate = getDateDaysAgo(7);
        break;
      case 'Last 28 days':
        startDate = getDateDaysAgo(28);
        break;
      case 'Last 90 days':
        startDate = getDateDaysAgo(90);
        break;
      case 'Last 365 days':
        startDate = getDateDaysAgo(365);
        break;
      case 'Current Year':
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now.getFullYear(), 11, 31);
        break;
      case 'Previous Year':
        startDate = new Date(now.getFullYear() - 1, 0, 1);
        endDate = new Date(now.getFullYear() - 1, 11, 31);
        break;
      default:
        // Handles months dynamically assuming the format is "Month-Year" e.g., "April-2024"
        if (period.includes('-')) {
          const parts = period.split('-');
          const month = getMonthIndex(parts[0]);
          const year = parseInt(parts[1], 10);
          if (month !== -1 && !isNaN(year)) {
            ({ startDate, endDate } = getStartAndEndOfMonth(year, month));
          } else {
            return data; // Invalid month or year
          }
        } else {
          return data; // No valid period matched
        }
        break;
    }
    return data.filter(item => {
      const itemDate = new Date(item.Date);
      return itemDate >= startDate && itemDate <= endDate;
    });
  };const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  };const getStartAndEndOfMonth = (year, month) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    return { startDate, endDate };
  };const getMonthIndex = (monthName) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
    return monthNames.indexOf(monthName);
  };

  const props={setGraphHeight, setGraphWidth, TabData}
  return (
    <>
      <div className='h-100 analytics'>
        <div className='p-1'><h4>{title}</h4></div>
        <div className='d-flex flex-row justify-content-between text-white'>
          <div style={{fontSize: 13, fontWeight: 600, color: '#343434'}}>
            <ul className="nav nav-tabs shadow" style={{ border: 'none' }}>
              {tabs.map(tab => (
                <li className="nav-item" key={tab.id}>
                  <span
                    className={`nav-link border-0 ${selectedTab === tab.id ? 'active' : ''}`}
                    onClick={() => handleTabChange(tab.id)}
                    style={navigationTab}>
                    {tab.label}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className='d-flex justify-content-between' style={{width: 200}}>
            <div>
              <button className="btn btn-secondary p-1 border-0"
                style={{borderRadius: 10, background: 'transparent',
                  width: 34, height: 34 }}
                  onClick={() => downloadData(selectedTab, TabData)}>
                <i className="bi bi-download" style={{color: '#001034'}}/>
              </button>
            </div>
            <div className="dropdown" type="button">
              <button className="btn btn-secondary dropdown-toggle
                text-start px-4 border-0" 
                type="button" id="durationDropdown" 
                data-bs-toggle="dropdown" aria-expanded="false"
                style={{
                  borderRadius: 8,
                  width: 160, height: 32, 
                  fontSize: 13, fontWeight: 500,
                  // background: 'rgb(52 66 102)'
                  }}>
                {selectedDuration}
              </button>
              <ul 
                className="dropdown-menu shadow border-0" 
                aria-labelledby="durationDropdown"
                style={{cursor: 'pointer', fontSize: 13, fontWeight: 500,}}>
                <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 7 days')}>Last 7 days</span></li>
                <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 28 days')}>Last 28 days</span></li>
                <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 90 days')}>Last 90 days</span></li>
                <li><span className="dropdown-item" onClick={() => handleDurationChange('Last 365 days')}>Last 365 days</span></li>
                <li><span className="dropdown-item" onClick={() => handleDurationChange('Lifetime')}>Lifetime</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div className='card SmallCardStyle2 shadow'
          style={{borderRadius: '0px 0px 20px 20px', height: `calc(${viewHeight} - 40px)`}}>
          <div className='d-flex flex-row justify-content-between h-100 w-100'>
            <div className='card-body px-4 py-5 w-auto'
              style={{fontSize: 14, fontWeight: 600,}}>
              {selectedTab === 'DataBreach' && 
                <DataBreachRecord {...props} />
              }
              {selectedTab === 'WebAnalytics' && 
                <WebAnalytics {...props} />            
              }
              {selectedTab === 'NetworkAnalytics' && 
                <NetworkAnalytics {...props} />
              }            
              {selectedTab === 'CloudAnalytics' && (
                upgradePlan == 0 ? (
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <h4>Upgrade the plan to use this feature</h4>
                  </div>
                ) : (
                  selectedCloud === 'GCloud' ? <GCloudAnalytics {...props} /> :
                  selectedCloud === 'AWSCloud' ? <AWSCloudAnalytics {...props} /> :
                  (selectedCloud === 'AzureCloud' && <AzureCloudAnalytics {...props} />)
                )
              )}
              {selectedTab === 'SaveConnectAnalytics' && (
                upgradePlan == 0 ? (
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <h4>Upgrade the plan to use this feature</h4>
                  </div>
                ) : (
                  selectedSecTools === 'Crowdstrike' ? <CrowdstrikeAnalytics {...props} /> :
                  selectedSecTools === 'Wireshark' ? <Wireshark {...props} /> :
                  selectedSecTools === 'Astra International' ? <AstraInternational {...props} /> :
                  selectedSecTools === 'Qualys' ? <Qualys {...props} /> :
                  selectedSecTools === 'Ettercap' ? <Ettercap {...props} /> :
                  selectedSecTools === 'EGO' ? <EGO {...props} /> :
                  selectedSecTools === 'SolarWinds' ? <SolarWinds {...props} /> :
                  selectedSecTools === 'Breachlock' ? <Breachlock {...props} /> :
                  selectedSecTools === 'Balbix' ? <Balbix {...props} /> :
                  selectedSecTools === 'Skybox' ? <Skybox {...props} /> :
                  selectedSecTools === 'Indusface' ? <Indusface {...props} /> :
                  selectedSecTools === 'SNORT' ? <SNORT {...props} /> :
                  selectedSecTools === 'GFILanGuard' ? <GFILanGuard {...props} /> :
                  selectedSecTools === 'VERACODE' ? <VERACODE {...props} /> :
                  selectedSecTools === 'Holm Security' ? <HolmSecurity {...props} /> :
                  selectedSecTools === 'Tripwire' ? <Tripwire {...props} /> :
                  selectedSecTools === 'Rapid' ? <Rapid {...props} /> :
                  selectedSecTools === 'INTRUDER' ? <INTRUDER {...props} /> :
                  selectedSecTools === 'Salesforce' ? <Salesforce {...props} /> :
                  selectedSecTools === 'Tenable' && <Tenable {...props} />    
                )            
              )}
            </div>
            
            {/* Icon List */}
            {selectedTab === 'CloudAnalytics'  && (
              <div className='d-flex flex-column mt-3 mb-3 mx-1 scrollbar-all-primary'>
                {cloudItems.map((button, index) => (
                  <button className={`btn btn-light 
                    ${upgradePlan ? '' : 'disabled'} m-1
                    position-relative rounded-3 border shadow-sm`}
                    style={{
                      background: selectedCloud === button.title ? '#f6f7f8' : '#ffffff',
                      outline: selectedCloud === button.title ? '1px solid #9e9e9e' : 'none',
                    }}
                    onClick={async() => handleCloudBtnClk(button)}>
                    <div className='d-flex justify-content-center align-content-center'
                      style={{ width: 60, height: 70 }}>
                      <img className='w-auto h-auto'
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        src={button.src} alt={button.alt} />
                    </div>
                  </button>
                ))}
              </div>
            )}
            {selectedTab === 'SaveConnectAnalytics'  && (
              <div className='d-flex flex-column mt-3 mb-3 mx-1 scrollbar-all-primary'>
                {securityToolsItems.map((button, index) => (
                  <button className={`btn btn-light 
                    ${upgradePlan ? '' : 'disabled'} m-1
                    position-relative rounded-3 shadow-sm`}
                    style={{
                      background: selectedSecTools === button.title ? '#f6f7f8' : '#ffffff',
                      outline: selectedSecTools === button.title ? '1px solid #9e9e9e' : 'none',
                  }}
                    onClick={async() => handleSecToolBtnClk(button)}>
                    <div className='d-flex justify-content-center align-content-center'
                      style={{ width: 60, height: 70 }}>
                      <img className='w-auto h-auto'
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        src={button.src} alt={button.alt} />
                    </div>
                  </button>
                ))}
              </div>
            )}
            <div className='card-body my-3 px-4 py-5 rounded-4 text-white h-auto'
              style={{background: '#6c757d', marginRight: 20, width: 300, maxWidth: 300}}>
              <div className='d-flex flex-column h-100 w-100'>
                <div style={{fontSize: 16, fontWeight: 600}}>Realtime</div>
                <div className='d-flex' style={{fontSize: 12, fontWeight: 400}}>                
                  <span>Updating Live</span>
                  <span className='px-2 d-flex justify-content-center align-items-center'>
                    <div className='pulsatingDot' />
                  </span>
                </div>
                <div className="border-bottom py-2"></div>
                <div className='scrollbar-all-secondary' style={{paddingRight: 10}}>
                  <div className='py-2 pt-3' 
                    style={{fontSize: 10}}>
                    <div className='pb-3' style={{fontSize: 12}}>
                      Data Breach Probality in 48hours
                    </div>  
                    <DataBreachProbability {...props}/>
                  </div>
                  <div className="border-bottom"></div>
                  <div className='d-flex flex-column py-2 pt-3'
                    style={{fontSize: 12, lineHeight: 1.8}}>
                    <p>
                      <span><b>Total Data Breach:</b> {TDB}</span><br/>
                      <span><b>Total Vulnerability:</b> {TV}</span><br/>
                      <span><b>Web Vulnerability:</b> {WV}</span><br/>
                      <span><b>Network Vulnerability:</b> {NV}</span><br/> 
                      {upgradePlan == 1 && <span><b>Cloud Misconfiguration:</b> {CM}</span>}<br/>
                      {upgradePlan == 1 && <span><b>Other Misconfiguration:</b> {OM}</span>}<br/>
                    </p>                  
                  </div>
                  <span className='text-center' style={{fontSize: 12}}>
                    <b>Get Instant Remidiation</b>
                  </span>
                </div>                
              </div>      
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DataBreachRecord = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <LineChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => {
          const formattedDate = new Date(date);
          return formattedDate.toLocaleDateString('en-US');}}
          // label={{ value: 'Date', offset:0, position: 'bottom' }}
          />
        <YAxis label={{ value: 'Breach Intensity', angle: -90, position: 'insideLeft' }}/>
        <Tooltip formatter={(value, name, props) => {
            return [`${value}`, tooltipKeyMapping[name]];
          }}/>
        <Legend />
        <Line 
          type="monotone" 
          dataKey="DBP" 
          stroke="#cf0000" 
          strokeWidth={2}
          activeDot={{ r: 4,}}           
        />
        <Line 
          type="monotone" 
          dataKey="DBR" 
          stroke="#82ca9d"
          strokeWidth={2}
          activeDot={{ r: 4, stroke: 'red', strokeWidth: 2, fill: 'red' }}           
        />
      </LineChart>
    </ResponsiveContainer>
  )
};

function DataBreachProbability (){
  const now = new Date();
  const futureDate = new Date(now);
  futureDate.setHours(futureDate.getHours() + 48);

  const RoundedBar = (props) => {
    const { x, y, width, height, fill } = props;
    return (
      <rect x={x} y={y}
        width={width} height={height} fill={fill}
        rx={3} // Set the border radius here
      />
    );
  };

  return(
    <ResponsiveContainer width="100%" height={130}>
      <BarChart data={data_breach_probability} 
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>        
        <XAxis 
          dataKey="Date"
          domain={[now, futureDate]}
          tickFormatter={(date) => {
            const formattedDate = new Date(date);
            return formattedDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
          }}
          tick={{ fill: 'white' }}
          axisLine={{ stroke: 'white' }}
        />
        <Tooltip labelStyle={{ color: 'black' }}/>
        <Bar
          dataKey="Probability"
          fill="#00BEFF"
          barSize={8}
          shape={<RoundedBar />}
        />
      </BarChart>      
    </ResponsiveContainer>
  )
};

const WebAnalytics = ({setGraphHeight, setGraphWidth, TabData}) =>{
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <LineChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis
          dataKey="Date"
          tickFormatter={(date) => {
            const formattedDate = new Date(date);
            return formattedDate.toLocaleDateString('en-US');
          }}
        />
        <YAxis />
        <Tooltip
          formatter={(value, name, props) => {
            return [`${value}`, tooltipKeyMapping[name]];
          }}
        />
        <Legend />
        <Line type="monotone" dataKey="TWV" stroke="#8884d8" 
          strokeWidth={2} activeDot={{ r: 4 }} 
        />
        <Line type="monotone" dataKey="CWV" stroke="#cf0000" 
          strokeWidth={2} activeDot={{ r: 4, stroke: 'red', strokeWidth: 2, fill: 'red' }}
        />
        <Line type="monotone" dataKey="SS" stroke="#ffd766" 
          strokeWidth={2} activeDot={{ r: 4 }} 
        />
      </LineChart>
    </ResponsiveContainer>
  )
};const NetworkAnalytics = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <LineChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis
          dataKey="Date"
          tickFormatter={(date) => {
            const formattedDate = new Date(date);
            return formattedDate.toLocaleDateString('en-US');
          }}
        />
        <YAxis />
        <Tooltip
          formatter={(value, name, props) => {
            return [`${value}`, tooltipKeyMapping[name]];
          }}
        />
        <Legend />
        <Line type="monotone" dataKey="TNV" stroke="#8884d8" 
          strokeWidth={2} activeDot={{ r: 4 }} 
        />
        <Line type="monotone" dataKey="CNV" stroke="#cf0000" 
          strokeWidth={2} activeDot={{ r: 4, stroke: 'red', strokeWidth: 2, fill: 'red' }}
        />
        <Line type="monotone" dataKey="SS" stroke="#ffd766" 
          strokeWidth={2} activeDot={{ r: 4 }} 
        />
      </LineChart>
    </ResponsiveContainer>
  )
};

const GCloudAnalytics = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const AWSCloudAnalytics = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const AzureCloudAnalytics = ({setGraphHeight, setGraphWidth, TabData}) =>{
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )  
};

const CrowdstrikeAnalytics = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const Wireshark = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const AstraInternational = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const Qualys = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const Ettercap = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const EGO = ({setGraphHeight, setGraphWidth, TabData}) => {
  return(
    <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
      <BarChart data={TabData}>
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
        <YAxis />
        <Tooltip formatter={(value, name, props) => [value, name]} />
        <Legend />
        <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
        <Bar dataKey="High" fill="#fc0000" name="High" />
        <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
      </BarChart>
    </ResponsiveContainer>
  )
};const SolarWinds = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Breachlock = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Balbix = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Skybox = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Indusface = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const SNORT = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const GFILanGuard = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const VERACODE = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const HolmSecurity = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Tripwire = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Rapid = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const INTRUDER = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Salesforce = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};const Tenable = ({setGraphHeight, setGraphWidth, TabData}) => {
return(
  <ResponsiveContainer width={setGraphWidth} height={setGraphHeight}>
    <BarChart data={TabData}>
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis dataKey="Date" tickFormatter={(date) => new Date(date).toLocaleDateString('en-US')} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => [value, name]} />
      <Legend />
      <Bar dataKey="Critical" fill="#6d2d9d" name="Critical" />
      <Bar dataKey="High" fill="#fc0000" name="High" />
      <Bar dataKey="Medium" fill="#ffc000" name="Medium" />
    </BarChart>
  </ResponsiveContainer>
)
};

const downloadData = (tabName, jsonData) => {
  const filename = `${tabName.toLowerCase()}_data.csv`;
  const csv = convertJsonToCsv(jsonData);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};
const convertJsonToCsv = (jsonData) => {
  const headers = Object.keys(jsonData[0]).join(',');
  const rows = jsonData.map(obj => Object.values(obj).join(',')).join('\n');
  return headers + '\n' + rows;
};

// Sample Data for Data Breach Probability
const generateData = () => {
  const data = [];
  let currentDate = new Date();
  for (let i = 0; i < 24; i++) {
    const probability = Math.random() * 100; // Generate random probability value
    data.push({ Date: new Date(currentDate), Probability: probability });
    currentDate.setHours(currentDate.getHours() + 2); // Increment date by 2 hours
  }return data;
};

// Custom Data Name
const tooltipKeyMapping = {
  DBP: 'Breach Probability',
  DBR: 'Breach Record',
  TWV: 'Total Web Vulnerability',
  TNV: 'Total Network Vulnerability',
  CWV: 'Critical Web Vulnerability',
  CNV: 'Critical Network Vulnerability',
  SS: 'Save Score',
};

const TDB = '4';    //Total Data Breach:
const WV = 20;      //Web Vulnerability
const NV = 12;      //Network Vulnerability
const TV = WV + NV; //Total Vulnerability
const CM = 14;      //Cloud Misconfiguration
const OM = 20;      //Other Misconfiguration

// Setup Endpoint According to backend
const api_base_url = process.env.REACT_APP_API_BASE_URL_LOCAL;
const api_key = process.env.REACT_APP_API_KEY;
const data_breach_probability = generateData();
const data_breach_record = `${api_base_url}/api/${api_key}/analytics/dbreach_rec_graph_data`;

const web_analytics_graph_data = `${api_base_url}/api/${api_key}/analytics/web_analytics_graph_data`;
const network_analytics_graph_data = `${api_base_url}/api/${api_key}/analytics/network_analytics_graph_data`;

// Cloud Services
const GCloud_graph_data = `${api_base_url}/api/${api_key}/analytics/gcloud_analytics_graph_data`;
const AWSCloud_graph_data = `${api_base_url}/api/${api_key}/analytics/aws_analytics_graph_data`;
const AzureCloud_graph_data = `${api_base_url}/api/${api_key}/analytics/azure_analytics_graph_data`;

// Security Tools
const Crowdstrike_graph_data = `${api_base_url}/api/${api_key}/analytics/crowdstrike_analytics_graph_data`;
const Wireshark_graph_data = `${api_base_url}/api/${api_key}/analytics/wireshark_analytics_graph_data`;
const AstraInternational_graph_data = `${api_base_url}/api/${api_key}/analytics/astra_international_analytics_graph_data`;
const Qualys_graph_data = `${api_base_url}/api/${api_key}/analytics/qualys_analytics_graph_data`;
const Ettercap_graph_data = `${api_base_url}/api/${api_key}/analytics/ettercap_analytics_graph_data`;
const EGO_graph_data = `${api_base_url}/api/${api_key}/analytics/ego_analytics_graph_data`;
const SolarWinds_graph_data = `${api_base_url}/api/${api_key}/analytics/solarwinds_analytics_graph_data`;
const Breachlock_graph_data = `${api_base_url}/api/${api_key}/analytics/breachlock_analytics_graph_data`;
const Balbix_graph_data = `${api_base_url}/api/${api_key}/analytics/balbix_analytics_graph_data`;
const Skybox_graph_data = `${api_base_url}/api/${api_key}/analytics/skybox_analytics_graph_data`;
const Indusface_graph_data = `${api_base_url}/api/${api_key}/analytics/indusface_analytics_graph_data`;
const SNORT_graph_data = `${api_base_url}/api/${api_key}/analytics/snort_analytics_graph_data`;
const GFILanGuard_graph_data = `${api_base_url}/api/${api_key}/analytics/gfi_languard_analytics_graph_data`;
const VERACODE_graph_data = `${api_base_url}/api/${api_key}/analytics/veracode_analytics_graph_data`;
const HolmSecurity_graph_data = `${api_base_url}/api/${api_key}/analytics/holm_security_analytics_graph_data`;
const Tripwire_graph_data = `${api_base_url}/api/${api_key}/analytics/tripwire_analytics_graph_data`;
const Rapid_graph_data = `${api_base_url}/api/${api_key}/analytics/rapid_analytics_graph_data`;
const INTRUDER_graph_data = `${api_base_url}/api/${api_key}/analytics/intruder_analytics_graph_data`;
const Salesforce_graph_data = `${api_base_url}/api/${api_key}/analytics/salesforce_analytics_graph_data`;
const Tenable_graph_data = `${api_base_url}/api/${api_key}/analytics/tenable_analytics_graph_data`;
