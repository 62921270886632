import React, { useContext, useEffect, useState } from 'react';
import { useUser } from '../../../context/contextUser';
import { useDesign } from '../../../context/contextDesign';
import { useChat } from '../../../context/contextChat';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const SavesetScore = ({ setScore }) => {
  const { activeNav } = useDesign();
  const { urlAssets, ipAssets, cloudAssets } = useUser();
  const [pathColor, setPathColor] = useState('');
  const [cardScore, setCardScore] = useState('');
  const [assets, setAssets] = useState([]);

  // Function to determine the score label
  const getCardScore = (score) => {
    return score >= 8
      ? 'Excellent'
      : score >= 6
      ? 'Good'
      : score >= 4
      ? 'Average'
      : 'Poor';
  };

  // Function to determine the path color
  const getPathColor = (score) => {
    return score >= 8
      ? '#92d14f'
      : score >= 6
      ? '#ffc000'
      : score >= 4
      ? '#fc0000'
      : '#6d2d9d';
  };

  useEffect(() => {
    // Update card score and path color when setScore changes
    setCardScore(getCardScore(setScore));
    setPathColor(getPathColor(setScore));
  }, [setScore]); // The effect runs when `setScore` changes

  return (
    <div className='pe-2 mb-2'>
      <div className="card rounded-4 SavesetScore" 
        style={{ width: 350, height: 243 }}>
        <div className="card-body d-flex justify-content-evenly align-items-center">
          <div className="d-flex align-items-start" style={{ height: 200 }}>
            <div className="Title" style={{ fontWeight: 500, lineHeight: 1.3 }}>
              <span style={{ fontSize: 30 }}>Security</span><br />
              <span style={{ fontSize: 38 }}>Audit</span><br />
              <span style={{ fontSize: 30 }}>Score</span>
            </div>
          </div>
          <div className="col-6 d-flex flex-column justify-content-center">
            <div className="circularProgressbar ps-2">
              <CircularProgressbar
                value={setScore === 0 ? (assets ? 0 : 1) : setScore * 10}
                text={`${setScore === 0 ? (assets ? 0 : 1) : setScore}`}
                strokeWidth={9}
                styles={buildStyles({
                  rotation: 0,
                  pathTransitionDuration: 0.6,
                  strokeLinecap: 'round', // 'butt' or 'round'
                  textSize: '33px', // Text size 
                  textColor: '#343434',
                  pathColor: pathColor, // Use the correct pathColor
                  trailColor: 'rgb(236, 236, 236)',
                })}
              />
            </div>
            <div className="pt-2 text-center d-flex flex-column justify-content-start" style={{ lineHeight: 1.2 }}>
              <div className='col'>R. Factor:</div> 
              <div className="col fs-5 fw-bold" style={{ color: pathColor }}>{cardScore}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavesetScore;