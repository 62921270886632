import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import parse from 'html-react-parser';

const RSSFeed = () => {
  const [feedData, setFeedData] = useState([]);
  const feedUrl = "https://www.ncsc.gov.uk/api/1/services/v1/all-rss-feed.xml";

  useEffect(() => {
    fetch(feedUrl)
      .then(response => response.text())
      .then(data => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/xml");

        const items = xmlDoc.querySelectorAll("item");
        const feedItems = [];

        items.forEach(item => {
          const title = item.querySelector("title").textContent;
          const description = item.querySelector("description")?.textContent;
          const link = item.querySelector("link").textContent;

          feedItems.push({ title, description, link });
        });

        setFeedData(feedItems);
      })
      .catch(error => {
        console.error("Error fetching RSS feed:", error);
      });
  }, []);

  return (
    <div className="container">
      <div id="feed-container" className="">
        {feedData.length > 0 ? (
          feedData.map((item, index) => (
            <div key={index} className="card mb-3 rounded-4 shadow">
              <div className='card-body'>
                <div className="h3">{item.title}</div>
                {item.description && <p className='fw-small'>{parse(item.description)}</p>}
                <a href={item.link} target="_blank" rel="noopener noreferrer" 
                  className="btn btn-primary btn-block border-0" style={{backgroundColor: 'rgb(0, 28, 99)'}}>
                  Read More
                </a>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">Loading RSS feed...</p>
        )}
      </div>
    </div>
  );
};

export default RSSFeed;