const api_base_url = process.env.REACT_APP_API_BASE_URL;
const api_key = process.env.REACT_APP_API_KEY;

// SignIn and SignUp
export const signin = async (credentials) => {
  const { username, password } = credentials;

  if (!username) {
    throw new Error('Email is required');
  }
  if (!password) {
    throw new Error('Password is required');
  }
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/signin`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      throw new Error('Sign In Failed');
    }

    const data = await response.json();
    // console.log('Successfully Signed In:', data);
    return data; // Return user data
  } catch (error) {
    console.error('Sign In Failed:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
export const signup = async (userData) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/signup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      // Log the response for debugging
      console.error('Response:', response);
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to sign up');
    }

    return response.json();
  } catch (error) {
    console.error('Error during signup:', error.message || error);
    throw error;
  }
};
// Basic Features
export const verify2FACode = async (userId, code) => {
  const token = sessionStorage.getItem('authToken2FA');
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/verifyMFA`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ code }),  // Send as an object
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to verify 2FA');
    }
    return response.json();
  } catch (error) {
    console.error('Error verifying 2FA:', error);
    throw error;
  }
};
export const uploadProfilePicture = async (userId, profilePictureFile) => {
  if (profilePictureFile) {
    const formData = new FormData();
    formData.append('profilePicture', profilePictureFile);

    await fetch(`${api_base_url}/api/${api_key}/users/${userId}/updateUserPic`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: formData
    });
  }
};
export const updateUserdata = async (userId, newUserData) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/updateUserData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
      },
      body: JSON.stringify(newUserData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to update current data');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating current data:', error);
    throw error;
  }
};
export const getUserProfile = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    if (!token) {throw new Error('No token found');}

    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.error === 'Token expired') {
        alert('Your session has expired. Please log in again.');
      } else {
        throw new Error(errorData.error || 'Failed to fetch user profile');
      }
    }
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};
export const getImageUrl = async (userId, imagePath) => {
  if (!userId || !imagePath) {
    throw new Error("User ID and image path are required");
  }

  // Construct the base URL
  const url = `${api_base_url}/api/${api_key}/users/${userId}/images${imagePath}`;

  return url;
};
export const getWifiInfo = async () => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/wifi`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
      }
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Wi-Fi information:', error);
    throw error;
  }
};
// Post Methods
export const postUrl = async (userId, url) => {
  try {
    if (!userId || !url) {
      throw new Error('User ID and URL are required');
    }

    const token = sessionStorage.getItem('authToken');
    if (!token) {
      throw new Error('Authentication token is missing');
    }
    
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/postUrl`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ url })
    });

    if (!response.ok) {
      const errorMessage = `Failed to post URL. Status: ${response.status} ${response.statusText}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    
    const logs = await response.json();
    // console.log('User Logs:', logs);
    return logs;
  } catch (error) {
    console.error('Error posting user URL:', error.message);
    throw error;
  }
};
export const postIp = async (userId, ip) => {
  // console.log('IP:', ip);
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/postIp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ ip }) // Include the URL in the request body
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user IP');
    }

    const logs = await response.json();
    console.log('User Logs:', logs);
    return logs;
  } catch (error) {
    console.error('Error posting user IP:', error);
    throw error;
  }
};
export const postGCloudKeys = async (userId, accessKey, secretKey) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/postGCloudKeys`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Include the Bearer token in the header
      },
      body: JSON.stringify({ accessKey, secretKey }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to Scan GCloud Keys');
    }

    return response.json();
  } catch (error) {
    console.error('Error during GCloud scan:', error);
    throw error;
  }
};
export const postAWSKeys = async (userId, accessKey, secretKey) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/postAWSKeys`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Include the Bearer token in the header
      },
      body: JSON.stringify({ accessKey, secretKey }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to Scan AWS Keys');
    }

    return response.json();
  } catch (error) {
    console.error('Error during AWS scan:', error);
    throw error;
  }
};
export const postAzureKeys = async (userId, accessKey, secretKey) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/postAzureKeys`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Include the Bearer token in the header
      },
      body: JSON.stringify({ accessKey, secretKey }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to Scan Azure Keys');
    }

    return response.json();
  } catch (error) {
    console.error('Error during Azure scan:', error);
    throw error;
  }
};
export const postMalUrl = async (userId, url) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/postMalUrl`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ url })
    });
    
    if (!response.ok) {
      const errorResponse = await response.text();  // Read the error message
      console.error('Error response:', errorResponse);
      throw new Error(`Failed to fetch user URL: ${errorResponse}`);
    }    
    return response.json();
  } catch (error) {
    console.error('Error posting user URL:', error);
    throw error;
  }
};
// Get Methods
export const getUrlAssets = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getUrlAssets`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user url details:', error);
    throw error;
  }
};
export const getUrlVulnerabilities = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getUrlVulnerabilities`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const data = await response.json();    
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Error fetching URL vulnerabilities:', error);
    return []; // Return an empty array on error
  }
};
export const getUrlEpss = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getUrlEpss`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const data = await response.json();    
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Error fetching URL vulnerabilities:', error);
    return []; // Return an empty array on error
  }
};
export const getIpAssets = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getIpAssets`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user url details:', error);
    throw error;
  }
};
export const getIpVulnerabilities = async (userId, index) => {
  try{
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getIpVulnerabilities`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(index),
    });

    return response.json();
  }catch(error){
    throw(error);
  }
};
export const getIpEpss = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getIpEpss`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const data = await response.json();    
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Error fetching URL vulnerabilities:', error);
    return []; // Return an empty array on error
  }
};
export const getCloudAssets = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getCloudAssets`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user url details:', error);
    throw error;
  }
};
export const getAwsData = async (userId, uuid, token) => {

};
export const getAzureData = async (userId, uuid, token) => {

};
export const getGCloudData = async (userId, uuid, token) => {

};
// export const getGCloudData = async (accessKey) => {
//   try {
//     const token = sessionStorage.getItem('authToken');
//     if (!token) {throw new Error('No token found');}

//     const response = await fetch(`${api_base_url}/api/${api_key}/users/${accessKey}/getGCloudData`, {
//       method: 'GET',
//       headers: { 
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//     });

//     if (!response.ok) {
//       const errorData = await response.json();
//       if (errorData.error === 'Token expired') {
//         alert('Your session has expired. Please log in again.');
//       } else {
//         throw new Error(errorData.error || 'Failed to fetch user profile');
//       }
//     }
 
//     return response.json();
//   } catch (error) {
//     console.error('Error fetching user profile:', error);
//     throw error;
//   }
// }
// export const getAWSData = async (accessKey) => {
//   try {
//     const token = sessionStorage.getItem('authToken');
//     if (!token) {throw new Error('No token found');}

//     const response = await fetch(`${api_base_url}/api/${api_key}/users/${accessKey}/getAWSData`, {
//       method: 'GET',
//       headers: { 
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//     });

//     if (!response.ok) {
//       const errorData = await response.json();
//       if (errorData.error === 'Token expired') {
//         alert('Your session has expired. Please log in again.');
//       } else {
//         throw new Error(errorData.error || 'Failed to fetch user profile');
//       }
//     }
 
//     return response.json();
//   } catch (error) {
//     console.error('Error fetching user profile:', error);
//     throw error;
//   }
// }
// export const getAzureData = async (accessKey) => {
//   try {
//     const token = sessionStorage.getItem('authToken');
//     if (!token) {throw new Error('No token found');}

//     const response = await fetch(`${api_base_url}/api/${api_key}/users/${accessKey}/getAzureData`, {
//       method: 'GET',
//       headers: { 
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`
//       },
//     });

//     if (!response.ok) {
//       const errorData = await response.json();
//       if (errorData.error === 'Token expired') {
//         alert('Your session has expired. Please log in again.');
//       } else {
//         throw new Error(errorData.error || 'Failed to fetch user profile');
//       }
//     }
 
//     return response.json();
//   } catch (error) {
//     console.error('Error fetching user profile:', error);
//     throw error;
//   }
// }

export const fetchUserLogs = async (userId) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/logs`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user logs');
    }

    const logs = await response.json();
    console.log('User Logs:', logs);
    return logs;
  } catch (error) {
    console.error('Error fetching user logs:', error);
    throw error;
  }
};

export const test = async (userId) => {
  try {
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/test`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
 
    return response.json();
  } catch (error) {
    console.error('Error fetching user url details:', error);
    throw error;
  }
};

export const chatBot = async (prompt) => {
  try {
    const response = await fetch(`https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=AIzaSyCvnY_8Bgb1cLiapYJN8wo0v_ZQwAG1JbI`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contents: [{
          parts: [{
            text: prompt // Use the provided prompt here
          }]
        }]
      }),
    });

    // Check if the response is ok (status 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse and return the JSON response
    return await response.json();
  } catch (error) {
    console.error('Error fetching user URL details:', error);
    throw error;
  }
};

export const postChatBackup = async (userId, message) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/postChatBackup`, {
      method: 'POST', // Changed to POST for saving data
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(message),
    });

    if (!response.ok) {
      throw new Error(`Failed to backup chat: ${response.statusText}`);
    }
    return response.json(); // Returning parsed JSON response
  } catch (error) {
    console.error('Chat Backup Error:', error); // Proper 
    throw error; 
  }
};

export const getChatBackup = async (userId) => {
  try {
    const token = sessionStorage.getItem('authToken');
    const response = await fetch(`${api_base_url}/api/${api_key}/users/${userId}/getChatBackup`, {  
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to retrieve chat backup: ${response.statusText}`);
    }
    // console.log(response);
    return response.json();

  } catch (error) {
    console.error('Chat Backup Error:', error);
    throw error;
  }
};