import React, { useEffect, useState, useContext } from "react";
import { useAuth } from '../../../context/contextAuth';
import { useUser } from '../../../context/contextUser';
import { useDesign } from '../../../context/contextDesign';
import { getImageUrl, 
  updateUserdata, uploadProfilePicture } from '../../../api/users';
import DefaultProfileImg from '../../asset/img/default-profile.avif';

const ProfileCard = () => {
  const { user, signout } = useAuth();
  const { toggleProfileCard } = useDesign();
  const { urlAssets, ipAssets } = useUser();
  // const { user } = useAuth();
  const upgradePlans = ['Default', 'Silver', 'Gold', 'Platinum'];
  const name_data = `${user?.first_name} ${user?.last_name}`;
  const company_data = `${user?.company}`;
  const email_data = `${user?.email}`;
  const registeredWebUrl_data = `${urlAssets[urlAssets.length - 1]?.url_scan? urlAssets[urlAssets.length - 1]?.url_scan:'none'}`;
  const registeredIP_data = `${ipAssets[ipAssets.length-1]?.ip_scan? ipAssets[ipAssets.length-1]?.ip_scan:'none'}`;
  const oldPassword_data = '';
  const newPassword_data = '';
  const confirmNewPassword_data = '';

  const [selectedItem, setSelectedItem] = useState('Profile');
  const [name, setName] = useState(name_data);
  const [companyName, setCompanyName] = useState(company_data);
  const [email, setEmail] = useState(email_data);
  const [registeredWebUrl, setRegisteredWebUrl] = useState(registeredWebUrl_data);
  const [registeredIP, setRegisteredIP] = useState(registeredIP_data);
  const [registeredAWS, setRegisteredAWS] = useState(false);
  const [registeredAzure, setRegisteredAzure] = useState(false);
  const [registeredGCloud, setRegisteredGCloud] = useState(false);
  const [profilePicture, setProfilePicture] = useState(DefaultProfileImg);
	const [profilePictureFile, setProfilePictureFile] = useState(null); 
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const settingsItems = [
    { item: 'Profile', component: Profile },
    { item: 'Security', component: SecurityComponent },
    { item: 'Privacy', component: PrivacyComponent },
    { item: 'T&C', component: TermsAndConditions },
  ];

  useEffect(() => {
    const companyName = user?.company || '';
    const profilePictureData = user?.profile_image_path
      ? getImageUrl(user?.profile_image_path)
      : generateInitialProfilePicture(companyName);
  
    setProfilePicture(profilePictureData);
    if (urlAssets) {
      if (urlAssets?.gcloud_access_key && urlAssets?.gcloud_secret_key) {
        setRegisteredGCloud(true);
      }
      else if (urlAssets?.azure_access_key && urlAssets?.azure_secret_key) {
        setRegisteredAzure(true);
      }
      else if (urlAssets?.aws_access_key && urlAssets?.aws_secret_key) {
        setRegisteredAWS(true);
      }
    }
  }, [user]);


  const generateInitialProfilePicture = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    // Set canvas dimensions
    canvas.width = 100;
    canvas.height = 100;
    
    // Background color (you can change this to any color you prefer)
    context.fillStyle = '#007bff';
    context.fillRect(0, 0, canvas.width, canvas.height);
    
    // Text color
    context.fillStyle = '#ffffff';
    context.font = 'bold 50px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    
    // Draw the first letter of the company name
    context.fillText(text.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2);
    
    // Return the data URL of the image
    return canvas.toDataURL('image/png');
  };  

	const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(URL.createObjectURL(file));
      setProfilePictureFile(file); // Store the file for uploading
    }
  };

	const handleSaveChanges = async (e) => {
		e.preventDefault();
		const userId = user?.id; // Assuming you have the user ID in user
		
		try {
			const newuser = {name, oldPassword, newPassword};
      await uploadProfilePicture(userId, profilePictureFile)
			if (oldPassword && newPassword && confirmNewPassword) {
				if (newPassword !== confirmNewPassword) {
					throw new Error('New password and confirm password do not match');
				}	
				await updateUserdata(userId, newuser);
			}
			console.log('Changes saved successfully!');
		} catch (error) {
			console.error('Error saving changes:', error);
		}
	};

  const handleItemClick = (item) => {setSelectedItem(item);};

  const props = {
    name, setName,
    companyName, setCompanyName,
    email, setEmail,
    registeredWebUrl, setRegisteredWebUrl,
    registeredIP, setRegisteredIP,
    registeredAWS, registeredAzure, registeredGCloud,
    oldPassword, setOldPassword,
    newPassword, setNewPassword,
    confirmNewPassword, setConfirmNewPassword,
    user, urlAssets,
  }

  return (
    <>
      <div className="profile-card position-absolute top-50 start-50 translate-middle 
        z-3 d-flex border-0 rounded-4 d-flex justify-content-left bg-white shadow-lg"
        style={{ height: 500, width: 700 }}>
        <div className="col-4 px-4 bg-dark rounded-4">
          <div className="d-flex flex-row py-5 px-3 text-white">
            <i className="bi bi-gear-fill" 
              style={{ fontSize: '20px', paddingRight: 10 }} />
            <h5>Settings</h5>
          </div>
          <div className="d-flex flex-column">
            {settingsItems.map((button, index) => (
              <button
                key={index}
                className={`btn btn-primary btn-light my-1 
                  position-relative rounded-3 border-0 text-white
                  ${selectedItem === button.item ? 'active ' : ''}`}
                style={{
                  fontSize: '16px', outline: 0,
                  background: selectedItem === button.item ? '#344266' : 'transparent',
                  transition: 'all 0.3s',
                }} onClick={() => handleItemClick(button.item)}>
                <div className='d-flex justify-content-left align-content-center px-2'
                  style={{ width: 100 }}>{button.item}</div>
              </button>
            ))}
          </div>          
        </div>
        <div className="col-8 d-flex flex-column align-items-left px-5 pt-3">
          <form onSubmit={handleSaveChanges}>
						<div className="mb-2 mt-2 d-flex flex-row justify-content-left align-items-center position-relative">
							<img src={profilePicture} alt=""
								width={100} height={100}
								className="rounded-circle profile-card-image"
								style={{ border: '4px solid #343434' }}
								onClick={() => document.getElementById('file-input').click()}
							/>
							<div className="profile-overlay">
								<i className="bi bi-camera"></i>
							</div>
							<input
								type="file"
								id="file-input"
								style={{ display: 'none' }} // Hide the file input
								onChange={handleProfilePictureChange}
							/>
						</div>
            <div>
              {selectedItem === 'Profile' && <Profile {...props} />}
              {selectedItem === 'Security' && <SecurityComponent />}
              {selectedItem === 'Privacy' && <PrivacyComponent />}
              {selectedItem === 'T&C' && <TermsAndConditions />}
            </div>
            <div className="position-absolute bottom-0 end-0 px-5 py-3">
              <button className="btn btn-primary border-0 my-3"
                type="submit"
                style={{
                  backgroundColor: 'rgb(52 66 102)', fontSize: '14px',
                  width: 150, height: 40
                }}>Save Changes</button>
            </div>
          </form>
        </div>
        <div className="position-absolute top-0 end-0 p-4">
          <i className="bi bi-x-lg" onClick={toggleProfileCard} />
        </div>
      </div>
      <div className="z-2 profile-card position-absolute
        top-50 start-50 translate-middle vw-100 vh-100"
        style={{ background: '#34343466' }} />
    </>
  );
};
export default ProfileCard;

const Profile = ({
  name, setName,
  companyName, setCompanyName,
  email, setEmail,
  registeredWebUrl, setRegisteredWebUrl,
  registeredIP, setRegisteredIP,
  registeredAWS, registeredAzure, registeredGCloud,
}) => {
  return (
    <>
      <div className="d-flex flex-row py-3">
        <span>
          <div className="fw-bold mb-2">Full Name:</div>
          <input className="form-control"
            type="text" value={name}
            onChange={(e) => setName(e.target.value)} />
        </span>
        <span className="px-2" />
        <span>
          <div className="fw-bold mb-2">Company Name:</div>
          <input className="form-control" disabled
            type="text" value={companyName}
            onChange={(e) => setCompanyName(e.target.value)} />
        </span>
      </div>
      <div>
        <span>
          <div className="fw-bold mb-2">Email:</div>
          <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled />
        </span>
      </div>
      <div className="d-flex flex-row py-3">
        <span>
          <div className="fw-bold mb-2">Registered Web URL:</div>
          <input className="form-control" disabled
					type="text" value={registeredWebUrl} 
					onChange={(e) => setRegisteredWebUrl(e.target.value)} />
        </span>
        <span className="px-2" />
        <span>
          <div className="fw-bold mb-2">Registered IP:</div>
          <input className="form-control" type="text" value={registeredIP} onChange={(e) => setRegisteredIP(e.target.value)} disabled />
        </span>
      </div>
      <div>
        <span>
          <div className="fw-bold mb-2">Registered Cloud:</div>
          {registeredAWS && <span className="tag px-2">AWS</span>}
          {registeredAzure && <span className="tag px-2">Azure</span>}
          {registeredGCloud && <span className="tag px-2">GCloud</span>}
        </span>
      </div>
    </>
  )
}

const SecurityComponent = ({
  oldPassword, setOldPassword,
  newPassword, setNewPassword,
  confirmNewPassword, setConfirmNewPassword,
}) => {
  return (
    <>
      <div className="py-3" style={{ width: 200 }}>
        <div className="d-flex flex-column mb-3">
          <span>
            <div className="fw-bold mb-2">Old Password:</div>
            <input className="form-control" type="password"
              value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
          </span>
        </div>
        <div className="d-flex flex-column mb-3">
          <span>
            <div className="fw-bold mb-2">New Password:</div>
            <input className="form-control" type="password"
              value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </span>
          <span className="d-flex flex-column mb-3" />
          <span>
            <div className="fw-bold mb-2">Confirm New Password:</div>
            <input className="form-control" type="password"
              value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
          </span>
        </div>
      </div>
    </>
  )
}

const PrivacyComponent = () => {
	return(
		<>
		
		</>
	)
}

const TermsAndConditions = () => {
	return(
		<>
		
		</>
	)
}
	


