import React from "react";
import './button.css';
import { useChat } from '../../../../context/contextChat';

const ChatIcon = () => {
  const { setPrompt, handleChat, setMount } = useChat();

  return (
    <div className="d-flex justify-content-center align-items-center rounded-3" 
      data-bs-toggle="modal" data-bs-target="#staticBackdropChatBot"
      onClick={() => setMount(true)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" className="bi bi-chat-right-dots-fill z-2 position-absolute" viewBox="0 0 16 16">
        <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353zM5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
      </svg>
      <div className="chat-iconBig position-absolute z-1"></div>
    </div>
  );
}

export default ChatIcon;