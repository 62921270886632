import React from "react";

const VulnerabilitiesDetected=({setScore})=>{
  return(
    <div className="pe-2 mb-2">
      <div className="card BreachProbability rounded-4"
        style={{width: 260, height: 'auto'}}>
        <div className="card-body text-center">
          <div className="card-title title">
            <span style={{fontSize: '21px'}}>Vulnerabilities</span><br/>
            <span style={{fontSize: '34px'}}>Detected</span>
          </div>                      
          <div className='d-flex justify-content-center align-items-center'>
            <h1 className="card-title score">
              {setScore}
            </h1>
          </div>
        </div>
      </div>
    </div>    
  )
}

export default VulnerabilitiesDetected;