import React, { useContext, useRef, useEffect, useState } from 'react';
import { useAuth } from '../context/contextAuth.js';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import {
  ResponsiveContainer, CartesianGrid, LineChart, 
  XAxis, Line, Tooltip, Legend } from 'recharts';
import { postIp, getIpVulnerabilities, getIpAssets, getIpEpss } from '../api/users';
import RiskExposurelist from './components/Cards/RiskExposureList-Ip';
import Vulnerabilities from './components/Cards/c-item-0-vulnerabilities';
import CriticalVul from './components/Cards/c-item-1-critical-vulnerability';
import SaveScore from './components/Cards/c-save-score';
// import SecurityAudit from './components/cards/c-security-audit-result';
import Solutions from './components/Cards/c-solutions';
import OpenPorts from './components/Cards/c-open-ports';
import Vid5 from './asset/vid/network/vid-4.webm';
import NetworkLoader from './components/Loaders/Loader-6/Loader-6';
import Loader from './components/Loaders/Loader-1/Loader-1';
import EpssCard from './components/Cards/c-epss-card';

const IpDashboard = ({title}) => {
  const { viewHeight } = useDesign();
  const {
    networkLoading, setNetworkLoading,
    ipAssets, ipDashboardView, setIpDashboardView,
    ipScanLoading, ipHeader, setIpHeader,
    ipIndex, setIpIndex
  } = useUser();
  
  useEffect(() => {    
    if(!ipScanLoading){
      if (ipAssets?.length > 0) {
        if(!networkLoading) setIpHeader(true);
        else setIpHeader(false);
        setIpDashboardView(true);
      } else if (ipAssets?.length === 0) {
        setIpHeader(false);
        setIpDashboardView(false);
      } else{
        setIpHeader(false);
        setIpDashboardView(false);
      }
    }
  }, [ipAssets, ipIndex]);
  
  return (
    <div className="h-100 network-dashboard">
      {networkLoading ? (
        <Loader />
      ) : (
        <>
          {/* {error && <div>{error}</div>} */}
          {ipHeader ? (
            <div className='p-1 pb-2'>
              <span className='h4'>{title} | </span>
              <span className='p-1 px-3 bg-dark rounded-3 text-white'>
                IP: {ipAssets[ipIndex]?.ip_address}
              </span>
            </div>
          ) : (
            <div className='p-1 pb-2'>
              <span className='h4'>{title}</span>
            </div>
          )}
          {!ipDashboardView ? (
            <div className='p-1 col-12 scrollbar-all-primary'>
              <div className="mt-1" style={{ height: viewHeight, paddingRight: 10 }}>
                <IpScanCard />
              </div>
            </div>
          ) : (
            <>
              {ipScanLoading ? (
                <div className="position-absolute top-50 start-50 translate-middle">
                  <div className="d-flex flex-column">
                    <div className="col"></div>            
                    <div className="col"><NetworkLoader /></div>            
                  </div>
                </div>
              ) : (
                <div className='scrollbar-all-primary d-flex flex-row' style={{ height: viewHeight, paddingRight: 10 }}>
                  <IpDashboardView />
                  <div className='d-flex flex-column px-3'>
                    {ipAssets && (
                      <>
                        {ipAssets.map((asset, index) => (
                          <a
                            key={index}
                            onClick={() => setIpIndex(index)}
                            style={{fontSize: 40, color: ipIndex === index ? '#a00000' : '#344266'}}
                          ><i className={`bi bi-${index+1}-square-fill`}/>
                          </a>
                        ))}
                        <a onClick={() => {
                            setIpDashboardView(false);                          
                            setIpHeader(false);
                          }}
                          style={{fontSize: 40, color: '#6c757d'}}
                        ><i className="bi bi-plus-square-fill"/>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
export default IpDashboard;

const IpScanCard = () => {
  const { userId } = useAuth();
  const {
    setIpScanLoading, setIpAssets, setIpVulTableAll, setIpEpssAll,
    setIpIndex, setIpDashboardView, setIpHeader
  } = useUser();

  const [ip, setIp] = useState('');
  const handleSubmit = async () => {
    setIpHeader(false);
    setIpDashboardView(true);
    setIpScanLoading(true);
    try {
      const getResponseIp = await postIp(userId, ip);
      const assets_count = getResponseIp.ipAssets_count;
      setIpIndex(assets_count);
      setIpHeader(true);
      await loadIpData(userId);      
    } catch (error) {
      setIpDashboardView(false);
      console.error('Error in handleSubmit:', error);
      // setError('An error occurred while submitting the form');
    } finally {
      setIpScanLoading(false);
    }
  };

  const loadIpData = async (userId) => {
    try {
      const getIpAssets_json = await getIpAssets(userId);
      const vulnerabilities = await getIpVulnerabilities(userId);
      const epss = await getIpEpss(userId);
      setIpAssets(getIpAssets_json);
      setIpVulTableAll(vulnerabilities);
      setIpEpssAll(epss);
    } catch (error) {
      console.error('Error fetching Ip vulnerabilities:', error);
      throw error;
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="col-8 card shadow-lg border-0 rounded-4" 
        style={{ height: 'calc(100% - 20%)', width: '76%' }}>
        <div className="d-flex flex-row align-items-center h-100 rounded-4">
          <div className="p-3 w-50" style={{ fontSize: '14px' }}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="mb-3 input-group rounded-2" style={{ height: 43, width: 260 }}>
                <span className="input-group-text bg-transparent">
                  <i className="bi bi-globe-americas" style={{ color: '#343434' }} />
                </span>
                <input
                  type="text"
                  placeholder="Enter ip"
                  className="p-2 text-center form-control"
                  onChange={(e) => setIp(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary border-0 shadow-sm"
                  type='button'
                  style={{
                    width: 260,
                    background: 'rgb(216, 0, 0)',
                    cursor: 'pointer',
                  }}
                  onClick={handleSubmit}>Submit
                </button>
              </div>
            </div>
          </div>
          <div className="h-100 w-50"
            style={{ background: '#001034', borderRadius: '0 1rem 1rem 0' }}>
            <video 
              className="img-fluid h-100"
              style={{ objectFit: 'cover', borderRadius: '0 1rem 1rem 0' }}
              src={Vid5} 
              alt="video" 
              autoPlay 
              loop 
              muted 
            />
          </div>
        </div>
      </div>        
    </div>
  );
};

function IpDashboardView({title}) {
  const { mainBodyHeight, mainBodyWidth, viewHeight } = useDesign();
  const { ipAssets, ipVulTableAll, ipEpssAll,
    setUrlEpssTable, showEpssCard, 
    setIpScanLoading, ipIndex } = useUser();
  const [ipVulTable, setIpVulTable] = useState(null);
  const [TVulScore, setTVulScore] = useState(0);
  const [CVulScore, setCVulScore] = useState(0);
  const [saveScore, setSaveScore] = useState(0);
  const [auditResult, setAuditResult] = useState('');
  
  const printRandomText = (textList) => {
    if (textList.length === 0) {
      return null;
    }
    const randomIndex = Math.floor(Math.random() * textList.length);
    const selectedText = textList[randomIndex];
    textList.splice(randomIndex, 1);
    return selectedText;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadIpData(ipIndex);
      } catch (error) {
        console.error('Error in loadIpData:', error);
      }
    };
    fetchData();        
  }, [ipIndex]);

  const loadIpData = async (ipIndex) => {
    if (ipAssets && ipVulTableAll) {      
      const currentUuid = ipAssets[ipIndex]?.ip_uuid;
      const filteredIpTable = ipVulTableAll.filter(vul => (vul.uuid === currentUuid && vul.severity != 'N/A'));
      const filteredEpssTable = ipVulTableAll.filter(vul => (vul.uuid === currentUuid && vul.baseSeverity != null));
      const filderedResult_soln = filteredIpTable.map(item => item.port);
      const cVul = filteredIpTable.filter(vul => vul.exploitability_score >= 9).length;
      // const totalImpactScore = filteredIpTable.reduce((sum, vul) => sum + parseFloat(vul.impact_score || 0), 0);
      // const SS = Math.round(filteredIpTable.length > 0 ? (((filteredIpTable.length * 10 - totalImpactScore) / (filteredIpTable.length * 10)) * 10) : 0);
      const SS = ipAssets[ipIndex]?.save_score - 1;
      const AuditResult = printRandomText(filderedResult_soln);
      setIpVulTable(filteredIpTable);
      setUrlEpssTable(filteredEpssTable);
      setTVulScore(filteredIpTable.length);
      setCVulScore(cVul?cVul:0);            
      setSaveScore(SS?SS:0);            
      setAuditResult(AuditResult);
      
      if (!filteredIpTable) {
        setIpScanLoading(true);
      } else {
        setIpScanLoading(false);
      }
    }
  };

  const props = { 
    title, mainBodyHeight, mainBodyWidth,
    viewHeight, ipVulTable
  };
  
  return (
    <>
      {showEpssCard && <EpssCard  />}
      <div className='d-flex justify-content-left'>
        <RiskExposurelist {...props} />
        <div className='scrollbar-all-primary'
          style={{ height: viewHeight }}>
          <div className='d-flex flex-row h-auto'>
            <div className='d-flex flex-column'>
              <Vulnerabilities setScore={TVulScore}/>
              <CriticalVul setScore={CVulScore}/>
            </div>
            <div className='d-flex flex-column'>
              <SaveScore setScore={saveScore}/>
              <Solutions setText={auditResult}/>
            </div>
          </div>
          <NetworkGraph />
        </div> 
      </div>
    </>
  );
};

const NetworkGraph = () => {
  const [IpGraphData, setIpGraphData] = useState([]);
  const api_base_ip = process.env.REACT_APP_API_BASE_URL_LOCAL;
  const api_key = process.env.REACT_APP_API_KEY;  
  const Ip_analytics_graph = `${api_base_ip}/api/${api_key}/analytics/network_analytics_graph_data`;
  const tooltipKeyMapping = {
    TWV: 'Total Vulnerability',
    CWV: 'Critical Vulnerability',
    SS: 'Save Score',
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const Ip_response = await fetch(Ip_analytics_graph);
        const Ip_data = await Ip_response.json();
        setIpGraphData(processData(Ip_data))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }; fetchData();
  }, []);

  const processData = (data) => {
    const now = new Date();
    let startDate, endDate;
    endDate = new Date(now);
    startDate = getDateDaysAgo(7);
    return data.filter(item => {
      const itemDate = new Date(item.Date);
      return itemDate >= startDate && itemDate <= endDate;
    });
  };
  const getDateDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  };

  return (
    <div className='card rounded-4' style={{ fontSize: 14, fontWeight: 600 }}>
      <div className='card-body'>
        <ResponsiveContainer width={"100%"} height={360}>
          <LineChart data={IpGraphData}>
            <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.4} />
            <XAxis 
              dataKey="Date" 
              tickFormatter={(date) => {
                const formattedDate = new Date(date);
                return formattedDate.toLocaleDateString('en-US');
              }}
            />
            <Tooltip 
              formatter={(value, name) => {
                return [`${value}`, tooltipKeyMapping[name]];
              }}
            />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="TNV" 
              stroke="#8884d8" 
              strokeWidth={2} 
              activeDot={{ r: 4 }}  
            />
            <Line 
              type="monotone" 
              dataKey="CNV" 
              stroke="#cf0000" 
              strokeWidth={2} 
              activeDot={{ r: 4, stroke: 'red', strokeWidth: 2, fill: 'red' }}
            />
            <Line 
              type="monotone" 
              dataKey="SS" 
              stroke="#ffd766" 
              strokeWidth={2} 
              activeDot={{ r: 4 }} 
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );  
};


const NetworkPieChart = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      
    </>
  );  
};