import React, { useContext, useState } from 'react';
import { useAuth } from '../context/contextAuth';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import { useNavigate, Link } from 'react-router-dom';

import Loader3 from './components/Loaders/Loader-3/Loader-3';
import LogoImg from './asset/img/logo/Save-Logo-White-2.png';

export default function SignIn(){
  const { user, loadingAuth, setLoadingAuth, getSignin } = useAuth();
  const { FadingComponent } = useDesign();
  const { setTempUserData } = useUser();
  // const [loading, setLoading] = useState(false);
  const [buttonClicked, setSidebarExtended] = useState(true);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toggleBtn = () => setSidebarExtended(!buttonClicked);
  const handleMouseEnter = () => setShowPopover(true);
  const handleMouseLeave = () => setShowPopover(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingAuth(true);
    try {
      const response = await getSignin({username, password});
      // console.log('response: ', response);
      if(response) navigate('/mfa');
    } catch (error) {
      console.error('Error verifying 2FA:', error);
      setError('Unable to Login');
    }
  };

  const eyeIcon = buttonClicked ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill';
  const inputType = buttonClicked ? 'password' : 'text';
  const widthVal = 270;

  return (
    <>
      <div className="signInWrapper" style={{ width: '100vw', height: '100vh', }}>
        {loadingAuth ? (<Loader3 />):(
          <div className="position-absolute top-50 start-50 translate-middle" style={{ width: widthVal, color: 'white', marginTop: '-6vh' }}>
            <div className="logo" style={{ padding: 40 }}>
              <img className="rounded mx-auto d-block" src={LogoImg} alt="Logo" style={{ height: 50 }} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon2">
                  <i className="bi bi-person-fill"></i>
                </span>
                <input
                  type="text"
                  placeholder="Username"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon2"
                />
                <span className="input-group-text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <i className="bi bi-app-indicator" />
                  {showPopover && (
                    <FadingComponent>
                      <div className="position-absolute top-50 start-100 translate-middle bg-transparent" style={{ marginLeft: 100, width: 200, display: 'inline-block' }}>
                        Use email as a Username
                      </div>
                    </FadingComponent>
                  )}
                </span>            
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon2">
                  <i className="bi bi-lock-fill"></i>
                </span>
                <input
                  type={inputType}
                  placeholder="Password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  aria-label="Password"
                  aria-describedby="basic-addon2"
                />
                <span className="input-group-text" id="basic-addon2">
                  <i className={eyeIcon} onClick={toggleBtn}></i>
                </span>
              </div>
              <div className="form-check mb-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                  checked={isRememberMeChecked}
                  onChange={(e) => setIsRememberMeChecked(e.target.checked)}
                  style={{ marginRight: 10 }}
                />
                <label className="form-check-label" htmlFor="rememberMe" style={{ fontSize: '14px' }}>
                  Remember me
                </label>
              </div>
              <center>
                {error && <div className="error" style={{ fontSize: '14px', color: '#ffd700' }}>{error}</div>}
                <button
                  className="btn"
                  type="submit"
                  style={{
                    border: 'none',
                    marginTop: 20,
                    width: widthVal,
                    height: 40,
                    color: 'white',
                    background: '#d80000',
                  }}>
                  Submit
                </button>
                <div style={{ fontSize: '14px', marginTop: 10 }}>
                  Not a member yet?
                  <Link to="/signup" style={{ color: 'red' }}>
                    <b> Sign Up</b>
                  </Link>
                </div>
              </center>
            </form>
          </div>
        )}
      </div>
    </>
  );
}