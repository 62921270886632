import React from 'react';

const title='Privacy Policy for SAVE'
const PrivacyPolicy =({mainBodyHeight})=> {
  const viewHeight=`calc(${mainBodyHeight} - 100px)`;

  return (
    <div className="h-100">
      <div className='p-1'><h4>{title}</h4></div>
      <div className='d-flex justify-content-left'>
        <div className='p-1 col-8'>
          <div className="mt-1 scrollbar-all-primary" 
            style={{height: viewHeight, paddingRight: 10}}>
              <PrivacyPolicyText />
            </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;


function PrivacyPolicyText(){
  return(
    <>
      <p>Last updated: 2024</p>
      <h6><strong>1. Introduction</strong></h6>
      <p>Welcome to the privacy policy for SAVE (Secure Vulnerability Assessment Tools for Enterprises). This policy explains how we collect, use, and protect your personal information when you use our services.</p>
      <h6><strong>2. Information We Collect</strong></h6>
      <p>Personal Information: We may collect personal information such as your name, email address, phone number, and company details when you sign up for our services or contact us for support.</p>
      <p>Device Information: We may collect information about the devices you use to access our services, such as device type, operating system, and browser information.</p>
      <p>Usage Data: We may collect information about how you use our services, including pages visited, features used, and interactions with our platform.</p>
      <h6><strong>3. How We Collect Information</strong></h6>
      <p>We collect information through various methods, including when you register for an account, use our services, or interact with our website or mobile applications. We may also collect information from third-party sources to improve our services.</p>
      <h6><strong>4. Use of Information</strong></h6>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and improve our services</li>
        <li>Personalize your experience</li>
        <li>Communicate with you, including for customer support and marketing purposes</li>
        <li>Analyze usage trends and preferences</li>
      </ul>
      <h6><strong>5. Sharing of Information</strong></h6>
      <p>We may share your information with third parties, including service providers and business partners, to provide you with our services or to improve our offerings. We may also share information in response to legal requests or to protect our rights or the rights of others.</p>
      <h6><strong>6. Data Retention</strong></h6>
      <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.</p>
      <h6><strong>7. User Rights</strong></h6>
      <p>You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to the processing of your personal information.</p>
      <h6><strong>8. Security Measures</strong></h6>
      <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or use. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee its absolute security.</p>
      <h6><strong>9. Cookies and Similar Technologies</strong></h6>
      <p>We use cookies and similar technologies to track user activities and preferences. You can manage your cookie preferences through your browser settings.</p>
      <h6><strong>10. Third-Party Links</strong></h6>
      <p>Our services may contain links to third-party websites or applications. Please note that the privacy policies of these third parties are not governed by our privacy policy.</p>
      <h6><strong>11. Changes to the Privacy Policy</strong></h6>
      <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>
      <h6><strong>12. Contact Information</strong></h6>
      <p>If you have any questions or concerns about this privacy policy or your personal information, please contact us at [Insert Contact Information].</p>
    </>
  )
}
