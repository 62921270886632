import React from "react";
import './Loader-7.css';

const Loader_7 = () =>{
  return(
    <div className="loader-7">
      <div className="progress"></div>
      <div className="progress"></div>
      <div className="progress"></div>
    </div>
  );
}
export default Loader_7;