export function jwtDecode(token) {
  if (!token) {
    throw new Error('Token is required');
  }

  function base64urlDecode(str) {
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    while (base64.length % 4) {
      base64 += '=';
    }
    return atob(base64);
  }

  const [header, payload, signature] = token.split('.');
  if (!header || !payload) {
    throw new Error('Invalid token');
  }

  const decodedHeader = JSON.parse(base64urlDecode(header));
  const decodedPayload = JSON.parse(base64urlDecode(payload));

  return {
    header: decodedHeader,
    payload: decodedPayload,
    signature: signature // Note: The signature is not decoded
  };
}
