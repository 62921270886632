import React from 'react';

const title='T&C for SAVE'
const TermsConditions =({mainBodyHeight})=> {
  const viewHeight=`calc(${mainBodyHeight} - 100px)`;

  return (
    <div className="h-100">
      <div className='p-1'><h4>{title}</h4></div>
      <div className='d-flex justify-content-left'>
        <div className='p-1'>
          <div className="mt-1 scrollbar-all-primary" 
           style={{height: viewHeight, paddingRight: 10}}>
              <TermsConditionsText />
            </div>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;


function TermsConditionsText(){
  return(
    <>
      <p>Last updated: 2024</p>
      <h6><strong>1. Introduction</strong></h6>
      <p>Welcome to the privacy policy for SAVE (Secure Vulnerability Assessment Tools for Enterprises). This policy explains how we collect, use, and protect your personal information when you use our services.</p>
      <h6><strong>2. Information We Collect</strong></h6>
      <p>Personal Information: We may collect personal information such as your name, email address, phone number, and company details when you sign up for our services or contact us for support.</p>
      <p>Device Information: We may collect information about the devices you use to access our services, such as device type, operating system, and browser information.</p>
      <p>Usage Data: We may collect information about how you use our services, including pages visited, features used, and interactions with our platform.</p>
      <h6><strong>3. How We Collect Information</strong></h6>
      <p>We collect information through various methods, including when you register for an account, use our services, or interact with our website or mobile applications. We may also collect information from third-party sources to improve our services.</p>
      <h6><strong>4. Use of Information</strong></h6>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and improve our services</li>
        <li>Personalize your experience</li>
        <li>Communicate with you, including for customer support and marketing purposes</li>
        <li>Analyze usage trends and preferences</li>
      </ul>
      <h6><strong>5. Sharing of Information</strong></h6>
      <p>We may share your information with third parties, including service providers and business partners, to provide you with our services or to improve our offerings. We may also share information in response to legal requests or to protect our rights or the rights of others.</p>
      <h6><strong>6. Data Retention</strong></h6>
      <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.</p>
      <h6><strong>7. User Rights</strong></h6>
      <p>You have the right to access, correct, or delete your personal information. You may also have the right to restrict or object to the processing of your personal information.</p>
      <h6><strong>8. Security Measures</strong></h6>
      <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or use. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee its absolute security.</p>
      <h6><strong>9. Cookies and Similar Technologies</strong></h6>
      <p>We use cookies and similar technologies to track user activities and preferences. You can manage your cookie preferences through your browser settings.</p>
      <h6><strong>10. Third-Party Links</strong></h6>
      <p>Our services may contain links to third-party websites or applications. Please note that the privacy policies of these third parties are not governed by our privacy policy.</p>
      <h6><strong>11. Changes to the Privacy Policy</strong></h6>
      <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>
      <h6><strong>12. Contact Information</strong></h6>
      <p>If you have any questions or concerns about this privacy policy or your personal information, please contact us at [Insert Contact Information].</p>
    </>
  )
}

function TermsConditionsText2(){
  return(
    <>
      <div className="row">
        <div className="col-4">
          <nav id="navbar-example3" className="h-100 flex-column align-items-stretch pe-4 border-end">
            <nav className="nav nav-pills flex-column">
              <a className="nav-link" href="#item-1">
                Item 1
              </a>
              <nav className="nav nav-pills flex-column">
                <a className="nav-link ms-3 my-1" href="#item-1-1">
                  Item 1-1
                </a>
                <a className="nav-link ms-3 my-1" href="#item-1-2">
                  Item 1-2
                </a>
              </nav>
              <a className="nav-link" href="#item-2">
                Item 2
              </a>
              <a className="nav-link" href="#item-3">
                Item 3
              </a>
              <nav className="nav nav-pills flex-column">
                <a className="nav-link ms-3 my-1" href="#item-3-1">
                  Item 3-1
                </a>
                <a className="nav-link ms-3 my-1" href="#item-3-2">
                  Item 3-2
                </a>
              </nav>
            </nav>
          </nav>
        </div>
        <div className="col-8">
          <div
            data-bs-spy="scroll"
            data-bs-target="#navbar-example3"
            data-bs-smooth-scroll="true"
            className="scrollspy-example-2 "
            tabIndex={0}
          >
            <div id="item-1">
              <h4>Item 1</h4>
              <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the page, the appropriate navigation link is highlighted. It's repeated throughout the component example. We keep adding some more example copy here to emphasize the scrolling and highlighting.</p><br/>
              <p>Keep in mind that the JavaScript plugin tries to pick the right element among all that may be visible. Multiple visible scrollspy targets at the same time may cause some issues.</p>
            </div>
            <div id="item-1-1">
              <h5>Item 1-1</h5>
              <p>Phasellus nec nisl vel felis venenatis congue eu ac libero. Nulla ac efficitur quam. Duis pharetra elit vitae libero aliquam, ut accumsan dui bibendum.</p>
            </div>
            <div id="item-1-2">
              <h5>Item 1-2</h5>
              <p>Integer et elit nec urna varius ullamcorper. Vivamus non enim sed augue vestibulum consequat ut eu libero. Proin nec congue ipsum.</p>
            </div>
            <div id="item-2">
              <h4>Item 2</h4>
              <p>Curabitur dignissim, urna vitae tincidunt aliquam, dolor nulla gravida turpis, vel tincidunt ipsum arcu nec ex. In non eros tincidunt, pulvinar metus in, feugiat orci.</p>
            </div>
            <div id="item-3">
              <h4>Item 3</h4>
              <p>Maecenas finibus purus non quam venenatis, vitae interdum tortor convallis. Sed interdum ultricies tincidunt. Aenean eu nunc auctor, feugiat mi nec, vulputate dolor.</p>
            </div>
            <div id="item-3-1">
              <h5>Item 3-1</h5>
              <p>Suspendisse ultricies libero at odio varius, nec rhoncus arcu lobortis. In et ex luctus, accumsan nunc eget, ultrices nisi.</p>
            </div>
            <div id="item-3-2">
              <h5>Item 3-2</h5>
              <p>Quisque auctor dui non lectus vehicula, in varius sapien accumsan. Nullam efficitur nulla sed urna tempor, eget suscipit erat sollicitudin.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
