import React, { useState, useEffect } from 'react';
import { useDesign } from '../../../context/contextDesign';
import { Link } from 'react-router-dom';

const getSeverity = (level) => {
  if (level >= 9.0) return "Critical";
  if (level >= 7.0) return "High";
  if (level >= 4.0) return "Medium";
  if (level >= 0.1) return "Low";
  return "None";
};

const calculateBackgroundColor = (riskFactor) => {
  switch (riskFactor) {
    case 'Critical':
      return '#6d2d9d'; // Purple
    case 'High':
      return '#fc0000'; // Red
    case 'Medium':
      return '#ffc000'; // Yellow
    case 'Low':
      return '#92d14f'; // Green
    default:
      return '#006dc0'; // Blue
  }
};

const RiskExposureList=({ viewHeight, urlVulTable })=>{
  const tableHeight = `calc(${viewHeight} - 80vh)`;
  const props = {viewHeight, urlVulTable };
  return (
    <div className="card rounded-4 w-100 h-100 border-1 overflow-hidden" style={{ width: '30vW' }}>
      <div className="card-body d-flex flex-column" style={{ height: tableHeight }}>
        <TableHeader />
        <div className='scrollbar-all-primary'>
          <ExposureTable {...props} />
        </div>
        <BtnArea />
      </div>
    </div>
  );
}
export default RiskExposureList;

const TableHeader = () => {
  return (
    <>
      <h4 className="card-title text-center mb-2 mt-2 py-3">Risk Exposure List</h4>
      <div className="col-12 mb-3 d-flex px-3 text-center" style={{ fontSize: 16, fontWeight: 600 }}>
        <div style={{ width: '60%' }}>Vulnerability</div>
        <div style={{ width: '20%' }}>Severity</div>
        <div style={{ width: '20%' }}>CVSS</div>
      </div>
    </>
  );
};

const ExposureTable = ({ viewHeight, tableHeight, urlVulTable }) => {
  const [vulnerabilities, setVulnerabilities] = useState([]);

  useEffect(() => {
    if (urlVulTable) {
      setVulnerabilities(urlVulTable);
    }
  }, [urlVulTable]);
  
  return (
    <div className="px-4">
      {vulnerabilities.map((vuln, index) => (
        <div className="row py-2" key={index}>
          <div style={{ width: '60%', fontSize: 14, fontWeight: 600 }}>
            {vuln.vuln_type}
          </div>
          <div className="text-center" style={{ width: '20%' }}>
            <span
              className="btn"
              style={{
                width: 60,
                fontSize: 13,
                fontWeight: 600,
                padding: 4,
                paddingTop: 2,
                paddingBottom: 2,
                background: calculateBackgroundColor(getSeverity(vuln.level)),
                color: '#ffffff',
              }}
            >
              {getSeverity(vuln.level)}
            </span>
          </div>
          <div className="text-center" style={{ width: '20%', fontSize: 15, fontWeight: 600 }}>
            {vuln.level}
          </div>
        </div>          
      ))}
      <div className="text-center py-3 mb-5">
        <div style={{ width: 220, height: 10 }}/>
      </div>
    </div>
  );
};

const BtnArea = () => {
  const { handleReportNav } = useDesign();
  return (
    <>
      <div className="w-100 text-center 
        position-absolute bottom-0 start-50 translate-middle-x">
        <div style={{
          height: '30px',
          width: '100%',
          background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
        }}/>
        <div className='bg-white rounded-4'>
          <Link to="/Reports">
            <button
              type="button"
              className="btn btn-primary position-relative border-0 my-4"
              style={{ backgroundColor: '#344266', width: 220, height: 40 }}
              onClick={() => handleReportNav('Cloud Reports')}>
              Show Detailed Report
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};