import CrowdstrikeIcon from '../modules/asset/img/save-connect/4-crowdstrike.png';
import WiresharkIcon from '../modules/asset/img/save-connect/5-wireshark.png';
import AstraInternationalIcon from '../modules/asset/img/save-connect/6-Astra-Internasional.png';
import QualysIcon from '../modules/asset/img/save-connect/7-qualys.png';
import EttercapIcon from '../modules/asset/img/save-connect/8-ettercap.png';
import EGOIcon from '../modules/asset/img/save-connect/9-e.go.png';
import SolarWindsIcon from '../modules/asset/img/save-connect/10-solar-winds.png';
import BreachlockIcon from '../modules/asset/img/save-connect/11-breachlock.png';
import BalbixIcon from '../modules/asset/img/save-connect/12-balbix.png';
import SkyboxIcon from '../modules/asset/img/save-connect/13-skybox.png';
import IndusfaceIcon from '../modules/asset/img/save-connect/14-Indusface.png';
import SNORTIcon from '../modules/asset/img/save-connect/15-SNORT.png';
import GFILanGuardIcon from '../modules/asset/img/save-connect/16-GFI-LanGuard.png';
import VERACODEIcon from '../modules/asset/img/save-connect/17-VERACODE.png';
import HolmSecurityIcon from '../modules/asset/img/save-connect/18-HOLM-SECURITY.png';
import TripwireIcon from '../modules/asset/img/save-connect/19-Tripwire.png';
import RapidIcon from '../modules/asset/img/save-connect/20-RAPID.png';
import INTRUDERIcon from '../modules/asset/img/save-connect/21-INTRUDER.png';
import SalesforceIcon from '../modules/asset/img/save-connect/22-Salesforce.png';
import TenableIcon from '../modules/asset/img/save-connect/23-tenable.png';

const securityToolsItems = [
    {
      src: CrowdstrikeIcon,
      title: 'Crowdstrike',
      alt: 'Crowdstrike Logo',
    },{
      src: WiresharkIcon,
      title: 'Wireshark',
      alt: 'Wireshark Logo',
    },{
      src: AstraInternationalIcon,
      title: 'Astra International',
      alt: 'Astra International Logo',
    },{
      src: QualysIcon,
      title: 'Qualys',
      alt: 'Qualys Logo',
    },{
      src: EttercapIcon,
      title: 'Ettercap',
      alt: 'Ettercap Logo',
    },{
      src: EGOIcon,
      title: 'EGO',
      alt: 'EGO Logo',
    },{
      src: SolarWindsIcon,
      title: 'SolarWinds',
      alt: 'SolarWinds Logo',
    },{
      src: BreachlockIcon,
      title: 'Breachlock',
      alt: 'Breachlock Logo',
    },{
      src: BalbixIcon,
      title: 'Balbix',
      alt: 'Balbix Logo',
    },{
      src: SkyboxIcon,
      title: 'Skybox',
      alt: 'Skybox Logo',
    },{
      src: IndusfaceIcon,
      title: 'Indusface',
      alt: 'Indusface Logo',
    },{
      src: SNORTIcon,
      title: 'SNORT',
      alt: 'SNORT Logo',
    },{
      src: GFILanGuardIcon,
      title: 'GFILanGuard',
      alt: 'GFILanGuard Logo',
    },{
      src: VERACODEIcon,
      title: 'VERACODE',
      alt: 'VERACODE Logo',
    },{
      src: HolmSecurityIcon,
      title: 'Holm Security',
      alt: 'Holm Security Logo',
    },{
      src: TripwireIcon,
      title: 'Tripwire',
      alt: 'Tripwire Logo',
    },{
      src: RapidIcon,
      title: 'Rapid',
      alt: 'Rapid Logo',
    },{
      src: INTRUDERIcon,
      title: 'INTRUDER',
      alt: 'INTRUDER Logo',
    },{
      src: SalesforceIcon,
      title: 'Salesforce',
      alt: 'Salesforce Logo',
    },{
      src: TenableIcon,
      title: 'Tenable',
      alt: 'Tenable Logo',
    },
  ];

  export default securityToolsItems;