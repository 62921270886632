import React, { useContext, useState, useEffect } from 'react';
import { useUser } from '../context/contextUser';
import { useDesign } from '../context/contextDesign';
import { useChat } from '../context/contextChat';
import Loader7 from './components/Loaders/Loader-7/Loader-7';

const Reports = ({ title  }) => {
  const { handleReportNav, selectReportTab } = useDesign();
  const [selectTab, setSelectedTab] = useState(selectReportTab);
  const dropdownList = [
    { title: webTableTitle, tab: 'Web Reports', component: WebReportTable },
    { title: networkTableTitle, tab: 'Network Reports', component: NetworkReportTable },
    { title: cloudTableTitle, tab: 'Cloud Reports', component: CloudReportTable },
  ];

  const handleReportTabChange = (tab) => {setSelectedTab(tab)};
  useEffect(() => {
    setSelectedTab(selectReportTab);
  }, []);

  return (
    <>
      <div className="p-1 pb-2">
        <h4>{title}</h4>
      </div>
      <div style={{ marginTop: -40}}>
        <div className="d-flex justify-content-end container mb-2">      
          <div className="dropdown" type="button">
            <button
              className="btn btn-secondary dropdown-toggle text-start px-4 border-0"
              type="button"
              id="durationDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                borderRadius: 8,
                width: 160,
                height: 30,
                fontSize: 12,
                fontWeight: 'bold',
                color: '#fff',
              }}>{selectTab}</button>
            <ul className="dropdown-menu shadow border-0"
              aria-labelledby="durationDropdown"
              style={{ cursor: 'pointer', fontSize: 12 }}>
              <li>
                <span className="dropdown-item" 
                  onClick={() => handleReportTabChange('Web Reports')}>
                  Web Reports
                </span>
              </li>
              <li>
                <span className="dropdown-item" 
                  onClick={() => handleReportTabChange('Network Reports')}>
                  Network Reports
                </span>
              </li>
              <li>
                <span className="dropdown-item" 
                  onClick={() => handleReportTabChange('Cloud Reports')}>
                  Cloud Reports
                </span>
              </li>
            </ul>
          </div>
        </div>
        {dropdownList.map((item, index) => ( 
          <React.Fragment key={index}>
            {selectTab === item.tab && (
              <div><item.component selectTab={selectTab}/></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
    
  );
};
export default Reports;

const WebReportTable = ({selectTab}) => {
  const { viewHeight } = useDesign();
  const { urlVulTableAll, urlAssets, urlIndex,
    urlVulTableToggle,
    } = useUser();
  const { setPrompt, setMount, handleChat } = useChat();
  const [vulTable, setVulTable] = useState([]);  
  const [modalContent, setModalContent] = useState(''); // State to store content for modal

  const currentUuid = urlAssets[urlIndex]?.url_uuid;
  const filteredVulTable = urlVulTableAll.filter(vul => (vul.uuid === currentUuid && !vul.level == 0));

  // Function to truncate text to a certain word limit
  const truncateText = (text, wordLimit = 10) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'; // Truncate and add ellipsis
    }
    return text;
  };

  // Function to handle "Read More" button click and open modal
  const handleDescriptionReadMore = (content) => {
    setModalContent(content);
    const modal = new window.bootstrap.Modal(document.getElementById('staticBackdropDescription'));
    modal.show();  // Show the modal

    const formattedData = `
      Give me more details on this as I am the CISO of the company.
      Mentions the actionable steps to be taken and delegate the tasks.
      Mention the step by step actions to be taken in points.
      ${content}
    `;
    setPrompt(String(formattedData));    
  };

  const handleSolutionReadMore = (content) => {
    setModalContent(content);
    const modal = new window.bootstrap.Modal(document.getElementById('staticBackdropSolution2'));
    modal.show();  // Show the modal   
  };

  useEffect(() => {
    if(!urlVulTableToggle){
      setVulTable(urlVulTableAll ? urlVulTableAll : []);
    }else{
      setVulTable(filteredVulTable ? filteredVulTable : []);
    }
  }, [urlVulTableToggle]);

  return (
    <div className="container">
      <div className="card rounded-4 py-4">
        { urlVulTableAll && urlVulTableAll.length === 0 ? (
          <div
            className='h1 d-flex justify-content-center align-items-center' 
            style={{ height: `calc(${viewHeight} - 56px)` }}>
            Web Scan not yet started
          </div>
        ):(
          <>
            <div className="card-body text-center">
              <h5 className="card-title">Web Vulnerabilities Report</h5>
            </div>
            <div className="px-4">
              <div 
                className='scrollbar-all-primary' 
                style={{ height: `calc(${viewHeight} - 120px)`, overflowY: 'auto' }}>
                <table className="table table-striped table-hover" >
                  <thead>
                    <tr style={{ 
                      fontSize: 13,
                      fontWeight: 'bold', 
                      position: 'sticky', top: 0, zIndex: 2, 
                      backgroundColor: 'white',                  
                    }}>
                      <th style={{ width: 200 }}>Alert</th>
                      <th style={{ width: 120 }}>Risk</th>
                      <th style={{ width: 140 }}>Confidence</th>
                      <th style={{ width: 300 }}>Description</th>
                      <th style={{ width: 300 }}>Solution</th>
                      <th style={{ width: 150 }}>URL</th>
                      <th style={{ width: 150 }}>Reference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vulTable.map((vuln, index) => (
                      <tr key={index} style={{ fontSize: 11 }}>
                        <td>{vuln.alert}</td>
                        <td>{vuln.risk}</td>
                        <td>{vuln.confidence}</td>
                        <td>
                          {/* Truncate Description */}
                          {truncateText(vuln.description, 10)}{' '}
                          {vuln.description.split(' ').length > 10 && (
                            <button
                              onClick={() => handleDescriptionReadMore(vuln.description)}
                              style={{ color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                              Read More
                            </button>
                          )}
                        </td>
                        <td>
                          {/* Truncate Solution */}
                          {truncateText(vuln.solution, 10)}{' '}
                          {vuln.solution.split(' ').length > 10 && (
                            <button
                              onClick={() => handleSolutionReadMore(vuln.solution)}
                              style={{ color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                              Read More
                            </button>
                          )}
                        </td>
                        <td >
                          <a href={vuln.url} target="_blank" rel="noopener noreferrer">{vuln.url}</a>
                        </td>
                        <td >
                          <a href={vuln.reference} target="_blank" rel="noopener noreferrer">Reference</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}        
      </div>

      {/* Modal for full content */}
      <div className=''>
        <div className="modal fade" id="staticBackdropDescription" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div className="modal-content bg-dark text-white">
              <div className="modal-header" style={{borderBottom: '1px solid #444444'}}>
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Full Content</h1>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {modalContent}
              </div>
              <div className="modal-footer" style={{ borderTop: '1px solid #444444' }}>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn text-white" 
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropChatBot"
                  style={{ background: '#344266' }}
                  onClick={async() => setMount(true)}>
                    <div className='d-flex justify-content-center align-items-center px-1'>
                      {/* <i className="bi bi-chat-right-dots-fill px-1"/> */}
                      <span className='pe-2'><Loader7 /></span>AI Assistance
                    </div>                
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className="modal fade" id="staticBackdropSolution2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div className="modal-content bg-dark text-white">
              <div className="modal-header" style={{borderBottom: '1px solid #444444'}}>
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Full Content</h1>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {modalContent}
              </div>
              <div className="modal-footer" style={{ borderTop: '1px solid #444444' }}>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NetworkReportTable = ({selectTab}) => {
  const { viewHeight } = useDesign();
  const { ipVulTableAll } = useUser();
  const setVulTable = ipVulTableAll || []; // Default to empty array if no data
  const [modalContent, setModalContent] = useState(''); // State for modal content

  const truncateText = (text, wordLimit = 10) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  const handleReadMore = (content) => {
    setModalContent(content); // Set content for modal
    const modal = new window.bootstrap.Modal(document.getElementById('staticBackdropReport'));
    modal.show(); // Open modal
  };

  return (
    <div className="container">
      <div className="card rounded-4 py-4">
        {ipVulTableAll && ipVulTableAll.length === 0 ? (
          <div 
            className='h1 d-flex justify-content-center align-items-center' 
            style={{ height: `calc(${viewHeight} - 56px)` }}>
            Network Scan not yet started
          </div>
        ):(
          <>
            <div className="card-body text-center">
              <h5 className="card-title">Network Vulnerabilities Report</h5>
            </div>
            <div className="px-4">
              <div 
                className='scrollbar-all-primary' 
                style={{ height: `calc(${viewHeight} - 120px)`, overflowY: 'auto' }}>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr style={{
                      fontSize: 13, fontWeight: 'bold', position: 'sticky', top: 0, zIndex: 2,
                      backgroundColor: 'white',
                    }}>
                      <th style={{ width: 250 }}>Vulnerability Title</th>
                      <th style={{ width: 100 }}>Type</th>
                      <th style={{ width: 100 }}>Platform</th>
                      <th style={{ width: 80 }}>Port</th>
                      <th style={{ width: 100 }}>Severity</th>
                      <th style={{ width: 120 }}>Exploitability</th>
                      <th style={{ width: 120 }}>Impact</th>
                      <th style={{ width: 160 }}>CVE/Codes</th>
                      <th style={{ width: 150 }}>Scan Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {setVulTable.map((vuln, index) => (
                      <tr key={index} style={{ fontSize: 11 }}>
                        <td>{vuln.vulnerability_title}</td>
                        <td>{vuln.vulnerability_type}</td>
                        <td>{vuln.platform}</td>
                        <td>{vuln.port}</td>
                        <td>{vuln.severity}</td>
                        <td>{vuln.exploitability_score}</td>
                        <td>{vuln.impact_score}</td>
                        <td>{vuln.codes}</td>
                        <td>{new Date(vuln.scan_time).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        
      </div>

      {/* Modal for full content */}
      <div className="modal fade" id="staticBackdropReport" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content" style={{ borderBottom: '1px solid #444444' }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Full Content</h1>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {modalContent}
            </div>
            <div className="modal-footer" style={{ borderTop: '1px solid #444444' }}>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CloudReportTable = ({selectTab}) => {
  const { viewHeight } = useDesign();
  const { cloudVulTableAll } = useUser();
  const setVulTable = cloudVulTableAll ? cloudVulTableAll : [];
  const [modalContent, setModalContent] = useState(''); // State to store content for modal

  // Function to truncate text to a certain word limit
  const truncateText = (text, wordLimit = 10) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'; // Truncate and add ellipsis
    }
    return text;
  };

  // Function to handle "Read More" button click and open modal
  const handleReadMore = (content) => {
    setModalContent(content);  // Set content to show in modal
    const modal = new window.bootstrap.Modal(document.getElementById('staticBackdropReport'));
    modal.show();  // Show the modal
  };

  return (
    <div className="container">
      <div className="card rounded-4 py-4">
        {cloudVulTableAll && cloudVulTableAll.length === 0 ? (
          <div 
            className='d-flex justify-content-center align-items-center flex-column text-center' 
            style={{ height: `calc(${viewHeight} - 56px)`, width: '100%' }}>
            <i classname="bi bi-plus-circle-dotted" style={{ fontSize: 60 }}/>
            <div className='h1'>Cloud Scan not yet started</div>
            <p className='col-7' style={{ fontSize: 14 }}>Initiate the cloud scan to detect potential vulnerabilities and security risks in your environment. Stay ahead by regularly monitoring and ensuring the integrity of your cloud infrastructure.</p>
          </div>
        ):(
          <>
            <div className="card-body text-center">
              <h5 className="card-title">Cloud Vulnerabilities Report</h5>              
            </div>
            <div className="px-4">
              <div 
                className='scrollbar-all-primary' 
                style={{ height: `calc(${viewHeight} - 120px)`, overflowY: 'auto' }}>
                <table className="table table-striped table-hover" >
                  <thead>
                    <tr style={{ 
                      fontSize: 13,
                      fontWeight: 'bold', 
                      position: 'sticky', top: 0, zIndex: 2, 
                      backgroundColor: 'white',                  
                    }}>
                      <th style={{ width: 200 }}>Alert</th>
                      <th style={{ width: 120 }}>Risk</th>
                      <th style={{ width: 140 }}>Confidence</th>
                      <th style={{ width: 300 }}>Description</th>
                      <th style={{ width: 300 }}>Solution</th>
                      <th style={{ width: 150 }}>URL</th>
                      <th style={{ width: 150 }}>Reference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {setVulTable.map((vuln, index) => (
                      <tr key={index} style={{ fontSize: 11 }}>
                        <td style={{ width: 200 }}>{vuln.alert}</td>
                        <td style={{ width: 120 }}>{vuln.risk}</td>
                        <td style={{ width: 140 }}>{vuln.confidence}</td>
                        <td style={{ width: 300 }}>
                          {/* Truncate Description */}
                          {truncateText(vuln.description, 10)}{' '}
                          {vuln.description.split(' ').length > 10 && (
                            <button
                              onClick={() => handleReadMore(vuln.description)}
                              style={{ color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                              Read More
                            </button>
                          )}
                        </td>
                        <td style={{ width: 300 }}>
                          {/* Truncate Solution */}
                          {truncateText(vuln.solution, 10)}{' '}
                          {vuln.solution.split(' ').length > 10 && (
                            <button
                              onClick={() => handleReadMore(vuln.solution)}
                              style={{ color: 'blue', background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                              Read More
                            </button>
                          )}
                        </td>
                        <td style={{ width: 150 }}>
                          <a href={vuln.url} target="_blank" rel="noopener noreferrer">{vuln.url}</a>
                        </td>
                        <td style={{ width: 150 }}>
                          <a href={vuln.reference} target="_blank" rel="noopener noreferrer">Reference</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Modal for full content */}
      <div className=''>
        <div className="modal fade" id="staticBackdropReport" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div className="modal-content" style={{borderBottom: '1px solid #444444'}}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Full Content</h1>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {modalContent}
              </div>
              <div className="modal-footer" style={{ borderTop: '1px solid #444444' }}>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const webTableTitle = 'Web Vulnerabilites Report';
const networkTableTitle = 'Network Vulnerabilites Report';
const cloudTableTitle = 'Cloud Misconfigurations Report';