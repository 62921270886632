import React from "react";

const CriticalVulnerability=({setScore})=>{
  return(
    <div className="pe-2 mb-2">
      <div className="card CriticalVulnerability rounded-4"
        style={{width: 260, height: 'auto'}}>
        <div className="card-body text-center">
          <div className="card-title title">
            <span style={{fontSize: '35px'}}>Critical</span><br/>
            <span style={{fontSize: '20px'}}>Vulnerability</span>          
          </div>
          <div className='d-flex justify-content-center align-items-center'>
            <h1 className="card-title score">
              {setScore}
            </h1>
          </div>     
        </div>
      </div>
    </div>
  )
}

export default CriticalVulnerability;